import React, { PropsWithChildren } from "react";
import { Button } from "@shoptet/ui";
import { ErrorBoundary as EB, ErrorBoundaryPropsWithComponent, FallbackProps } from "react-error-boundary";
import { Trans, useTranslation } from "react-i18next";
import { airbrakeNotify } from "../airbrake";
import { useAppSettings } from "../providers/appSettingsProvider";
import { Icon } from "./Icons";

export const ErrorFallbackComponent = ({}: Partial<FallbackProps>) => {
  const { apiToken } = useAppSettings();
  const { t } = useTranslation("translation", { keyPrefix: "Error Boundary" });

  return (
    <div style={{ minHeight: "80vh", alignItems: "center", display: "flex" }}>
      <div style={{ width: "390px", marginInline: "auto" }}>
        <div style={{ textAlign: "center" }}>
          <Icon className="icon-size-96" kind="warning" style={{ color: "#DB1B4E" }} />
        </div>
        <h3 style={{ textAlign: "center", color: "#DB1B4E" }}>{t("Headline")}</h3>
        <div style={{ textAlign: "center", fontSize: "12px", lineHeight: "16px", margin: "20px 0px" }}>
          <Trans components={{ a: <a /> }} i18nKey="Sub text" t={t} />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            data-test-id="retry-error-button"
            variant="default"
            onClick={() => (window.location.href = `${window.location.href.replace(/\?.*/, "")}?api_token=${apiToken}`)}
          >
            {t("Button")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ErrorBoundary = ({
  children,
  ...rest
}: PropsWithChildren<Omit<ErrorBoundaryPropsWithComponent, "FallbackComponent">>) => {
  return (
    <EB {...rest} FallbackComponent={ErrorFallbackComponent} onError={airbrakeNotify} onReset={rest.onReset}>
      {children}
    </EB>
  );
};
