import React from "react";
import { Button } from "@shoptet/ui";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AdvancedSetup } from "../../elements/AdvancedSetup";
import { NextIcon } from "../../elements/Icons";
import { InfoBox } from "../../elements/InfoBox";
import { MonthlyBudget } from "../../elements/MonthlyBudget";
import { ProgressStepper, StepsT } from "../../elements/ProgressStepper";
import { CurrencyT, WizardStepT, useCreditsQuery } from "../../graphql/generated/graphql";
import { formatMoney } from "../../i18n/formatNumbers";
import { useSetWizardStepEffect } from "./useSetWizardStepEffect";

export const FirstPage = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Wizard First Page" });
  useSetWizardStepEffect({ wizardStep: WizardStepT.FirstT });
  const { data } = useCreditsQuery();

  const navigate = useNavigate();

  const recommendedAmountNumber = data?.organization?.recommendedMonthlyBudget.amount || 3000;
  const recommendedMonthlyBudget = formatMoney({
    amount: recommendedAmountNumber,
    currency: data?.organization?.recommendedMonthlyBudget.currency || CurrencyT.CzkT,
  });
  return (
    <div>
      <h1>{t("Headline")}</h1>
      <ProgressStepper currentStep={StepsT.STEP_SETUP} />
      <InfoBox>{t("InfoBox")}</InfoBox>

      <h2>{t("Monthly Budget Headline")}</h2>
      <p>
        <Trans
          components={{ strong: <strong /> }}
          i18nKey="Monthly Budget Text with budget"
          t={t}
          values={{ recommendedMonthlyBudget }}
        />
      </p>
      <div className="v2form">
        <MonthlyBudget />
      </div>

      <br />
      <AdvancedSetup />
      <br />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <Button data-test-id="continue-to-next-step" onClick={() => navigate("/wizard/suggestion-generator")}>
          <span style={{ display: "flex", alignItems: "center" }}>
            {t("Continue to next step")}
            &nbsp;
            <NextIcon />
          </span>
        </Button>
      </div>
    </div>
  );
};
