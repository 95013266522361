import React from "react";
import { useTranslation } from "react-i18next";
import imageContent from "../../assets/image-info-banner-content.png";
import imageSearch from "../../assets/image-info-banner-search.png";
import imageShopping from "../../assets/image-info-banner-shopping.png";

export const InfoBanner = ({}) => {
  const { t } = useTranslation("translation", { keyPrefix: "Wizard Image Selection Page" });
  return (
    <div
      className="flexWrapUnder800"
      style={{ marginBottom: "20px", display: "flex", flexDirection: "row", gap: "30px", justifyContent: "center" }}
    >
      <div
        style={{
          border: "2px solid rgb(157, 202, 87)",
          minWidth: "400px",
          display: "flex",
          flexDirection: "column",
          flexGrow: 2,
        }}
      >
        <div style={{ padding: "20px", display: "flex", flexDirection: "row", gap: "30px", justifyContent: "center" }}>
          <img alt={t("Image info banner search image alternate")} src={imageSearch} width="156" />
          <img alt={t("Image info banner content image alternate")} src={imageContent} width="156" />
        </div>
        <div
          style={{
            padding: "25px",
            flexGrow: 1,
            textAlign: "center",
            color: "rgb(157, 202, 87)",
            fontSize: "20px",
            background: "rgba(157, 202, 87, 0.1)",
          }}
        >
          {t("Image info banner selection")}
        </div>
      </div>

      <div
        style={{
          border: "2px solid rgb(166, 166, 168)",
          minWidth: "300px",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <div style={{ padding: "20px", display: "flex", flexDirection: "row", gap: "30px", justifyContent: "center" }}>
          <img alt={t("Image info banner shopping image alternate")} src={imageShopping} width="156" />
        </div>
        <div
          style={{
            padding: "25px",
            flexGrow: 1,
            textAlign: "center",
            color: "rgb(166, 166, 168)",
            fontSize: "20px",
            background: "rgba(166, 166, 168, 0.1)",
          }}
        >
          {t("Image info banner shopping")}
        </div>
      </div>
    </div>
  );
};
