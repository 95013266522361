export const getIframeUrl = () => {
  try {
    if (window.top) {
      return window.top.location.origin + window.top.location.pathname;
    }
    return window.location.origin + window.location.pathname;
  } catch (err) {
    return null;
  }
};
