import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion } from "../Accordion";
import { WarningBox } from "../InfoBox";
import { ExcludeProducts } from "./ExcludeProducts";

export const AdvancedSetup = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.AdvancedSetup" });
  return (
    <Accordion header={t("Headline")}>
      <WarningBox>{t("Info description")}</WarningBox>

      <h3>{t("Exclude products")}</h3>
      <p>{t("Exclude products Text")}</p>
      <ExcludeProducts />
    </Accordion>
  );
};
