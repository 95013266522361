import React, { useCallback, useState } from "react";
import { Button, RadioField } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useChangeCampaignSettingsMutation, useOrganizationSettingsQuery } from "../../graphql/generated/graphql";
import { CheckmarkIcon } from "../Icons";
import { SmallLoader } from "../SmallLoader";

export const AudienceSignals = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.AdvancedSetup" });
  const [value, setValue] = useState<boolean>(true);
  const [isSaved, setIsSaved] = useState(false);

  const { data, loading } = useOrganizationSettingsQuery({
    onCompleted(dataResponse) {
      setValue(
        typeof dataResponse.organization?.audienceSignals === "undefined" ||
          dataResponse.organization?.audienceSignals === null
          ? true
          : dataResponse.organization?.audienceSignals
      );
    },
  });

  const [changeCampaignSetting, { loading: mutationLoading }] = useChangeCampaignSettingsMutation({
    onCompleted: () => {
      setIsSaved(true);
      setTimeout(() => setIsSaved(false), 2000);
    },
  });

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value === "true";
      setValue(newValue);
    },
    [setValue]
  );

  if (loading || !data?.organization) {
    return <SmallLoader />;
  }

  return (
    <div>
      <RadioField
        checked={value}
        disabled={mutationLoading || isSaved}
        labelAfter="Zapnout Audience Signals"
        name="audience_signals"
        value="true"
        onChange={onChange}
      />
      <RadioField
        checked={!value}
        disabled={mutationLoading || isSaved}
        labelAfter="Vypnout Audience Signals"
        name="audience_signals"
        value="false"
        onChange={onChange}
      />
      {(data.organization?.audienceSignals === value && !mutationLoading && !isSaved && <span />) || (
        <Button
          data-test-id="save-audience-signals"
          disabled={mutationLoading || isSaved}
          style={{ margin: "10px 10px 0 0" }}
          variant="action"
          onClick={() => changeCampaignSetting({ variables: { audienceSignals: value } })}
        >
          {(isSaved && (
            <span style={{ display: "flex", alignItems: "center" }}>
              <CheckmarkIcon /> &nbsp; {t("Saved")}
            </span>
          )) ||
            (mutationLoading && t("Saving")) ||
            t("Save")}
        </Button>
      )}
    </div>
  );
};
