import React, { useEffect, useState } from "react";
import { Button, TextField, Tooltip } from "@shoptet/ui";
import { difference } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import {
  ActiveTextsAssetLinesQueryT,
  AssetLineDraftStateT,
  AssetLineKindT,
  OrganizationValidateTextsQueryT,
  SuggestionKindT,
  SuggestionT,
  TextSuggestionsQueryT,
  ValidationErrorKindT,
  useCreateAssetLineMutation,
} from "../../graphql/generated/graphql";
import {
  DeleteAssetLineAction,
  DisapproveSuggestionAction,
  RestoreDeleteDraftStateAssetlineAction,
} from "../AssetLineActions";
import { stateOfItem } from "../ImageAssetLines";
import { RemainingIndicator } from "../RemainingIndicator";
import "./index.css";

type TextSuggestionT = Pick<SuggestionT, "id" | "kind" | "content">;

type TextSuggestionPlaceholderPropsT = {
  kind: "loading" | "missing";
};
const TextSuggestionPlaceholder = ({ kind }: TextSuggestionPlaceholderPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.AssetLineActions" });
  const buttons = (
    <span className="addon">
      <Button disabled={true} style={{ marginRight: "8px" }} variant="action">
        {t("Approve")}
      </Button>
      <DisapproveSuggestionAction id="-1" disabled />
      <Button style={{ marginLeft: "10px" }} variant="muted" disabled>
        {t("Edit")}
      </Button>
    </span>
  );
  return (
    <div className={kind === "loading" ? "loadingAnimation" : "TextAssetLine-Missing"}>
      <TextField
        addon={kind === "loading" && buttons}
        style={{ width: "90ch" }}
        value={kind === "loading" ? "Generujeme" : "Chybí"}
        disabled
      />
    </div>
  );
};

type CustomSuggestionT = {
  content: string;
  id: string;
  kind: SuggestionKindT;
};

type TextSuggestionWithEditPropsT = {
  draft?: boolean;
  handleSaveCustomSuggestion?: (customSuggestion: CustomSuggestionT, position: number) => void;
  idInStructure?: number;
  isCustomSuggestion?: boolean;
  maxLength: number;
  minLength: number;
  onDissaproveClick?: () => void;
  suggestion: TextSuggestionT;
  withoutLoading?: boolean;
};
const TextSuggestionWithEdit = ({
  draft,
  handleSaveCustomSuggestion,
  idInStructure = 0,
  isCustomSuggestion = false,
  maxLength,
  minLength,
  onDissaproveClick,
  suggestion,
  withoutLoading,
}: TextSuggestionWithEditPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.AssetLineActions" });
  const [approveAssetLine, {}] = useCreateAssetLineMutation();
  const [loaded, setLoaded] = useState(!!withoutLoading);
  const [editing, setEditing] = useState(false);
  const [content, setContent] = useState(suggestion.content || "");
  const contentLength = content.trim().length;
  const wrongLength = contentLength < minLength || contentLength > maxLength;
  const showApproveButton = isCustomSuggestion ? editing : true;

  useEffect(() => {
    const timeout = setTimeout(() => setLoaded(true), Math.round(Math.random() * 300) + 200);
    return () => clearTimeout(timeout);
  });

  if (!loaded) {
    return <TextSuggestionPlaceholder kind="loading" />;
  }

  const onSaveSuggestion = () => {
    if (handleSaveCustomSuggestion) {
      handleSaveCustomSuggestion(suggestion, idInStructure);
    }
  };

  const buttons = (
    <span className="addon">
      {editing && (
        <span style={{ color: contentLength <= maxLength ? "inherit" : "#db1b4e", marginRight: "10px" }}>
          {t("Character counter message", { contentLength, maxLength })}
        </span>
      )}
      {showApproveButton && (
        <Button
          data-test-id={`${suggestion.kind}-approve`}
          disabled={editing && wrongLength}
          variant="action"
          onClick={() => {
            approveAssetLine({
              variables: {
                draft,
                content,
                kind: suggestion.kind as unknown as AssetLineKindT,
                suggestionId: suggestion.id,
              },
            }).then(() => {
              onSaveSuggestion();
            });
          }}
        >
          {t(editing ? "Save" : "Approve")}
        </Button>
      )}{" "}
      {!editing && !isCustomSuggestion && (
        <span onClick={onDissaproveClick}>
          <DisapproveSuggestionAction id={suggestion.id} kind={suggestion.kind} style={{ marginLeft: "5px" }} />
        </span>
      )}{" "}
      {editing && (
        <Button
          data-test-id={`${suggestion.kind}-cancel-edit`}
          style={{ marginLeft: "10px" }}
          variant="muted"
          onClick={() => {
            setContent(suggestion.content || "");
            setEditing(false);
          }}
        >
          {t("Cancel")}
        </Button>
      )}
      {!editing && (
        <Button
          data-test-id={`${suggestion.kind}-edit`}
          style={{ marginLeft: "5px" }}
          variant="muted"
          onClick={() => {
            setEditing(true);
          }}
        >
          {t("Edit")}
        </Button>
      )}{" "}
    </span>
  );

  return (
    <TextField
      addon={buttons}
      disabled={!editing}
      style={{ width: "90ch" }}
      value={content}
      onChange={(event) => setContent(event.target.value)}
    />
  );
};

type AssetOrSuggestionIdT = {
  assetLineId?: string;
  suggestionId?: string;
} | null;

type ItemTextAssetLineT = NonNullable<
  NonNullable<ActiveTextsAssetLinesQueryT["organization"]>["activeTextsAssetLines"]
>[0];
type ItemSuggestionT = NonNullable<NonNullable<TextSuggestionsQueryT["organization"]>["textSuggestions"]>[0];

type TextAssetLinesPropsT = {
  activeTextAssetLines: ItemTextAssetLineT[];
  draft?: boolean;
  errors: NonNullable<
    NonNullable<NonNullable<OrganizationValidateTextsQueryT["organization"]>["validateTexts"]>["errors"]
  >;
  generateMoreSuggestions: () => void;
  generatingMoreSuggestions: boolean;
  hideSubtext?: boolean;
  kind: SuggestionKindT;
  textSuggestions: ItemSuggestionT[];
};

const ERRORS = {
  [ValidationErrorKindT.TooShortT]: "validation error too short",
  [ValidationErrorKindT.TooLongT]: "validation error too many",
};

export const CONFIGURATION = {
  [SuggestionKindT.BusinessNameT]: {
    translationKey: "BusinessName",
    limit: 1,
    minLimit: 1,
    maxLength: 30,
    minLength: 3,
  },

  [SuggestionKindT.VideoT]: {
    translationKey: "Video",
    limit: 5,
    minLimit: 0,
    maxLength: 1024,
    minLength: 10,
  },

  [SuggestionKindT.ShortHeadlineT]: {
    translationKey: "ShortHeadline",
    limit: 15,
    minLimit: 3,
    maxLength: 30,
    minLength: 3,
  },

  [SuggestionKindT.LongHeadlineT]: {
    translationKey: "LongHeadline",
    limit: 5,
    minLimit: 1,
    maxLength: 90,
    minLength: 3,
  },

  [SuggestionKindT.ShortDescriptionT]: {
    translationKey: "ShortDescription",
    limit: 1,
    minLimit: 1,
    maxLength: 60,
    minLength: 3,
  },

  [SuggestionKindT.LongDescriptionT]: {
    translationKey: "LongDescription",
    limit: 4,
    minLimit: 1,
    maxLength: 90,
    minLength: 3,
  },
};

type StructureT = { [k: string]: AssetOrSuggestionIdT };

export const TextAssetLines = ({
  activeTextAssetLines,
  draft,
  errors,
  generateMoreSuggestions,
  generatingMoreSuggestions,
  hideSubtext,
  kind,
  textSuggestions,
}: TextAssetLinesPropsT) => {
  const { limit, maxLength, minLength, minLimit, translationKey } = CONFIGURATION[kind];
  const { t: tPage } = useTranslation("translation", { keyPrefix: "Wizard Text Selection Page" });
  const { t } = useTranslation("translation", { keyPrefix: `Wizard Text Selection Page.${translationKey}` });
  const [gotStuckGettingSuggestions, setGotStuckGettingSuggestions] = useState<boolean>(false);
  const [suggestionStuckTimer, setSuggestionStuckTimer] = useState<NodeJS.Timeout | null>(null);

  const startSuggestionCountdown = () => {
    // Clear the previous timer if any
    if (suggestionStuckTimer) {
      clearTimeout(suggestionStuckTimer);
    }

    // Set a new timer for 60 seconds
    const newTimer = setTimeout(() => {
      setGotStuckGettingSuggestions(true);
    }, 60000);

    // Store the timer reference in state
    setSuggestionStuckTimer(newTimer);
  };

  const resetSuggestionCountdown = () => {
    setGotStuckGettingSuggestions(false); // Reset the gotStuckGettingSuggestions state
    startSuggestionCountdown(); // Restart the countdown
  };

  useEffect(() => {
    // Start the countdown when the component mounts
    startSuggestionCountdown();

    // Clean up the timer when the component unmounts
    return () => {
      if (suggestionStuckTimer) {
        clearTimeout(suggestionStuckTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const approvedAssetLines = activeTextAssetLines.filter(
    (assetLine) => (assetLine.kind as string) === (kind as string)
  );
  const possibleSuggestions = textSuggestions.filter(
    (suggestion) => suggestion.kind === kind && !suggestion.disapprovedAt && suggestion.assetLineCount === 0
  );
  const selectedErrors = errors.filter((error) => (error.attribute as string) === (kind as string));

  const draftDeletedAssetLines = approvedAssetLines.filter((line) => line.draftState === AssetLineDraftStateT.DeleteT);
  const notDeletedCount = approvedAssetLines.length - draftDeletedAssetLines.length;

  const calculateNewStructure = () => {
    const approvedAssetLinesArray = approvedAssetLines.filter(
      (assetLine) => assetLine.draftState !== AssetLineDraftStateT.DeleteT
    );
    const possibleSuggestionsArray = [...possibleSuggestions];
    return Array.from({ length: limit })
      .map((_, index) => index + 1)
      .reduce((acc, value) => {
        let val = null;
        const approvedAssetLine = approvedAssetLinesArray.shift();
        if (approvedAssetLine) {
          val = { assetLineId: approvedAssetLine.id };
        } else {
          const possibleSuggestion = possibleSuggestionsArray.shift();
          if (possibleSuggestion) {
            val = { suggestionId: possibleSuggestion.id };
          }
        }
        return {
          ...acc,
          [value.toString()]: val,
        };
      }, {});
  };

  const [structure, setStructure] = useState<StructureT>(calculateNewStructure);

  const assetLinesById = approvedAssetLines.reduce((acc, assetLine) => ({ ...acc, [assetLine.id]: assetLine }), {}) as {
    [k: string]: ItemTextAssetLineT;
  };
  const suggestionById = possibleSuggestions.reduce(
    (acc, suggestion) => ({ ...acc, [suggestion.id]: suggestion }),
    {}
  ) as { [k: string]: ItemSuggestionT };

  useEffect(() => {
    const approvedAssetLinesIds = approvedAssetLines
      .filter((assetLine) => assetLine.draftState !== AssetLineDraftStateT.DeleteT)
      .map((assetLine) => assetLine.id);
    const possibleSuggestionIds = possibleSuggestions.map((suggestion) => suggestion.id);
    const usedSugggestionsIds = Object.values(structure)
      .map((el) => el?.suggestionId)
      .filter((el) => !!el);
    const usedAssetLineIds = Object.values(structure)
      .map((el) => el?.assetLineId)
      .filter((el) => !!el);
    const notUsedSuggestions = difference(possibleSuggestionIds, usedSugggestionsIds);

    const notUsedApprovedAssetLines = difference(approvedAssetLinesIds, usedAssetLineIds);
    const out = {} as StructureT;

    const assetLinesByIdInEffect = approvedAssetLines.reduce(
      (acc, assetLine) => ({ ...acc, [assetLine.id]: assetLine }),
      {}
    ) as {
      [k: string]: ItemTextAssetLineT;
    };

    Object.keys(structure).map((position) => {
      if (!structure[position]) {
        out[position] = notUsedSuggestions.length > 0 ? { suggestionId: notUsedSuggestions.shift() } : null;
      } else if (
        structure[position]?.suggestionId &&
        possibleSuggestionIds.indexOf(structure[position]?.suggestionId || "") === -1
      ) {
        // suggestion was removed!
        // should look for not assigned asset!!!
        if (notUsedApprovedAssetLines.length > 0) {
          out[position] = { assetLineId: notUsedApprovedAssetLines.shift() };
        } else {
          // should assign new suggestion
          out[position] = notUsedSuggestions.length > 0 ? { suggestionId: notUsedSuggestions.shift() } : null;
        }
      } else if (
        structure[position]?.suggestionId &&
        possibleSuggestionIds.indexOf(structure[position]?.suggestionId || "") !== -1
      ) {
        // suggestion has not changed
        out[position] = structure[position];
      } else if (
        structure[position]?.assetLineId &&
        approvedAssetLinesIds.indexOf(structure[position]?.assetLineId || "") === -1
      ) {
        // asset line was removed!
        out[position] = notUsedSuggestions.length > 0 ? { suggestionId: notUsedSuggestions.shift() } : null;
      } else if (
        structure[position]?.assetLineId &&
        approvedAssetLinesIds.indexOf(structure[position]?.assetLineId || "") !== -1
      ) {
        // asset line has not changed
        if (
          assetLinesByIdInEffect[structure[position]?.assetLineId || ""]?.draftState === AssetLineDraftStateT.DeleteT
        ) {
          out[position] = notUsedSuggestions.length > 0 ? { suggestionId: notUsedSuggestions.shift() } : null;
        } else {
          out[position] = structure[position];
        }
      }
    });

    if (JSON.stringify(out) !== JSON.stringify(structure)) {
      if (notUsedSuggestions.length === 0 && !generatingMoreSuggestions) {
        generateMoreSuggestions();
      }

      setStructure(out);
    }
  }, [
    structure,
    setStructure,
    approvedAssetLines,
    generateMoreSuggestions,
    possibleSuggestions,
    generatingMoreSuggestions,
  ]);

  const headerContent = (
    <span>
      (
      <span
        style={{
          color: notDeletedCount === 0 || notDeletedCount > limit || notDeletedCount < minLimit ? "#db1b4e" : "#04C800",
        }}
      >
        {notDeletedCount}
      </span>{" "}
      z {limit})
    </span>
  );

  const handleSaveCustomSuggestion = (customSuggestion: CustomSuggestionT, position: number) => {
    const newCustomSuggestionId = customSuggestion.id;

    setStructure((prevStructure) => {
      const newStructure = { ...prevStructure };

      // Directly place the custom suggestion in the specified position
      newStructure[position] = { suggestionId: newCustomSuggestionId };

      return newStructure;
    });
  };

  const structureKeys = Object.keys(structure);
  structureKeys.sort((a, b) => parseInt(a) - parseInt(b));
  return (
    <div className="v2form TextAssets">
      <h2>
        {t("Headline")} {headerContent} <Tooltip text={t("Tooltip")} />
      </h2>
      {!hideSubtext && (
        <p>
          <Trans components={{ strong: <strong /> }} i18nKey="Text" t={t} values={{ minLimit, limit }} />
        </p>
      )}
      {structureKeys.map((id) => {
        const suggestionOrAssetLine = structure[id];
        if (suggestionOrAssetLine?.assetLineId && assetLinesById[suggestionOrAssetLine.assetLineId]) {
          const assetLine = assetLinesById[suggestionOrAssetLine.assetLineId];
          const draftState = stateOfItem(draft, assetLine.draftState);
          return (
            <div
              key={id}
              data-test-id={`${kind}-approved-item`}
              className={`TextAssetLine ${
                draft && assetLine.draftState ? `TextAssetLine--${assetLine.draftState}` : ""
              }`}
            >
              <TextField
                style={{ width: "90ch" }}
                value={assetLine.content}
                addon={
                  <span className="addon">
                    {draftState === "existing" && (
                      <DeleteAssetLineAction draft={draft} id={assetLine.id} width="auto" />
                    )}
                    {draftState === "draft_create" && (
                      <>
                        <span style={{ color: "#9DCA57", fontWeight: 700, marginRight: "10px" }}>
                          {tPage("Will be added")}
                        </span>
                        <DeleteAssetLineAction
                          draft={draft}
                          id={assetLine.id}
                          style={{ marginLeft: "10px" }}
                          width="auto"
                        />
                      </>
                    )}
                    {draft && assetLine.draftState === AssetLineDraftStateT.DeleteT && (
                      <>
                        <span style={{ color: "#DB1B4E", fontWeight: 700 }}>{tPage("Will be removed")}</span>
                        <RestoreDeleteDraftStateAssetlineAction
                          id={assetLine.id}
                          style={{ marginLeft: "10px" }}
                          width="auto"
                        />
                      </>
                    )}
                  </span>
                }
                disabled
              />
            </div>
          );
        } else if (suggestionOrAssetLine?.suggestionId && suggestionById[suggestionOrAssetLine.suggestionId]) {
          return (
            <div key={id} data-test-id={`${kind}-possible-suggestion-item`}>
              <TextSuggestionWithEdit
                draft={draft}
                maxLength={maxLength}
                minLength={minLength}
                suggestion={suggestionById[suggestionOrAssetLine.suggestionId]}
                onDissaproveClick={resetSuggestionCountdown}
              />
            </div>
          );
        } else if (gotStuckGettingSuggestions) {
          const blankSuggestion = {
            id: uuidv4(),
            kind,
            content: tPage("It is no longer possible to generate a new suggestion"),
          };
          return (
            <div key={id}>
              <TextSuggestionWithEdit
                draft={draft}
                handleSaveCustomSuggestion={handleSaveCustomSuggestion}
                idInStructure={parseInt(id)}
                isCustomSuggestion={true}
                maxLength={maxLength}
                minLength={minLength}
                suggestion={blankSuggestion}
                withoutLoading={true}
              />
            </div>
          );
        } else {
          return (
            <div key={id} data-test-id={`${kind}-generating-item`}>
              <TextSuggestionPlaceholder kind="loading" />
            </div>
          );
        }
      })}

      {draftDeletedAssetLines.map((assetLine) => {
        const draftState = stateOfItem(draft, assetLine.draftState);
        return (
          <div
            key={assetLine.id}
            className={`TextAssetLine ${draft && assetLine.draftState ? `TextAssetLine--${assetLine.draftState}` : ""}`}
            data-test-id={`${kind}-approved-item`}
          >
            <TextField
              style={{ width: "90ch" }}
              value={assetLine.content}
              addon={
                <span style={{ marginLeft: "10px" }}>
                  {draftState === "existing" && <DeleteAssetLineAction draft={draft} id={assetLine.id} width="auto" />}
                  {draftState === "draft_create" && (
                    <>
                      <span style={{ color: "#9DCA57", fontWeight: 700 }}>{tPage("Will be added")}</span>
                      <DeleteAssetLineAction
                        draft={draft}
                        id={assetLine.id}
                        style={{ marginLeft: "10px" }}
                        width="auto"
                      />
                    </>
                  )}
                  {draft && assetLine.draftState === AssetLineDraftStateT.DeleteT && (
                    <>
                      <span style={{ color: "#DB1B4E", fontWeight: 700 }}>{tPage("Will be removed")}</span>
                    </>
                  )}
                </span>
              }
              disabled
            />
          </div>
        );
      })}
      {selectedErrors.length > 0 && (
        <div style={{ color: "#db1b4e", margin: "10px 0" }}>
          {selectedErrors.map((error) => error?.kind && t(ERRORS[error.kind]))}
        </div>
      )}

      <div>
        {generatingMoreSuggestions && (
          <>
            <RemainingIndicator defaultSeconds={60}>
              {tPage("generating more suggestions")}
              <br />
            </RemainingIndicator>
          </>
        )}
      </div>
    </div>
  );
};
