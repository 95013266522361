import { MoneyFragmentT } from "../graphql/generated/graphql";

export const getDecimalsCount = (num: number | string | null) => {
  if (!num) {
    return 0;
  }
  const numStr = typeof num === "number" ? String(num) : num;

  if (numStr.includes(".") || numStr.includes(",")) {
    return numStr.split(/[,.]/)[1].length;
  }

  return 0;
};

const getDecimals = (amount: number, decimals?: number, defaultDecimals?: number) => {
  if (typeof decimals === "undefined") {
    if (Math.round(amount * 100) - Math.floor(amount) * 100 !== 0) {
      return defaultDecimals;
    }
    return 0;
  }

  return decimals;
};

export const numberWithThousandDelimiter = (value: number | string): string => {
  return value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");
};

export const formatFloat = (value: number | null) => {
  if (value || value === 0) {
    const shouldHideDecimals = Math.round(value * 100) - Math.floor(value) * 100 === 0;
    return numberWithThousandDelimiter(value.toFixed(shouldHideDecimals ? 0 : 2));
  }
  return "-";
};

export const formatInteger = (value: number | null) => {
  if (value || value === 0) {
    return numberWithThousandDelimiter(value.toFixed(0));
  }
  return "-";
};

const CURRENCY_UNIT: { [k: string]: string } = {
  CZK: "Kč",
  EUR: "€",
};

export const formatCurrencyUnit = (currency?: string) => {
  if (!currency) {
    return "";
  }
  return CURRENCY_UNIT[currency] || currency;
};

export const formatMoney = ({ amount, currency }: MoneyFragmentT, decimals?: number) => {
  if ((amount || amount === 0) && currency) {
    return `${numberWithThousandDelimiter(amount.toFixed(getDecimals(amount, decimals, 2)))} ${formatCurrencyUnit(
      currency
    )}`;
  } else if (amount || amount === 0) {
    return numberWithThousandDelimiter(amount.toFixed(getDecimals(amount, decimals, 2)));
  }
  return "-";
};

export const formatPercents = (value?: number | null, decimals?: number) => {
  if (value || value === 0) {
    const shouldHideDecimals = Math.round(value * 100) - Math.floor(value) * 100 === 0;
    return `${numberWithThousandDelimiter(value.toFixed(shouldHideDecimals ? 0 : getDecimals(value, decimals, 2)))} %`;
  }
  return "-";
};
