import React from "react";
import { TextField } from "@shoptet/ui";
import { ActiveTextsAssetLinesQueryT, SuggestionKindT } from "../../graphql/generated/graphql";

type PreviewPropsT = {
  activeTextAssetLines: NonNullable<NonNullable<ActiveTextsAssetLinesQueryT["organization"]>["activeTextsAssetLines"]>;
  kind: SuggestionKindT;
};

export const Preview = ({ activeTextAssetLines, kind }: PreviewPropsT) => {
  const approvedAssetLines = activeTextAssetLines.filter(
    (assetLine) => (assetLine.kind as string) === (kind as string)
  );
  return (
    <div className="TextSelection">
      {approvedAssetLines.map((assetLine) => (
        <div key={assetLine.id}>
          <TextField style={{ width: "90ch" }} value={assetLine.content} disabled />
        </div>
      ))}
    </div>
  );
};
