import React, { useCallback, useState } from "react";
import { Button, CheckboxField, IconButton, SystemMessage } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import {
  OrganizationCategoriesQueryT,
  useOrganizationCategoriesQuery,
  useSelectCategoriesMutation,
} from "../../graphql/generated/graphql";
import { CheckmarkIcon } from "../Icons";
import { SmallLoader } from "../SmallLoader";

type CategoryTreeKindT = "select" | "exclude";

type CategoryLinePropsT = {
  addCategory: (id: string) => void;
  categories?: NonNullable<OrganizationCategoriesQueryT["organization"]>["categories"];
  category: NonNullable<NonNullable<OrganizationCategoriesQueryT["organization"]>["categories"]>[0];
  disabled?: boolean;
  firstLevel?: boolean;
  kind: CategoryTreeKindT;
  removeCategory: (id: string) => void;
  selectedCategoryIds: string[];
};

const TreeBranch = () => (
  <svg fill="none" height="1035" viewBox="0 0 12 1035" width="12" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 0V1034H12" stroke="#E9E9E9" />
  </svg>
);

const CategoryLine = ({
  addCategory,
  categories,
  category,
  disabled,
  firstLevel,
  kind,
  removeCategory,
  selectedCategoryIds,
}: CategoryLinePropsT) => {
  const isSelected = selectedCategoryIds.indexOf(category.id) !== -1;
  const childs = (categories || []).filter((cat) => cat.parentGuid === category.guid);
  const hasChilds = childs.length > 0;

  const [shown, setShown] = useState(
    isSelected || childs.some((child) => (kind === "exclude" ? child.excluded : child.selected))
  );

  const excludedCategory = kind === "select" && !!category.excluded;
  const parentCategory = (categories || []).find((cat) => cat.guid === category.parentGuid);
  const excludedParentCategory =
    kind === "exclude" && parentCategory && selectedCategoryIds.indexOf(parentCategory.id) !== -1;

  return (
    <li style={firstLevel ? {} : { margin: "0 0 0 20px" }}>
      {!firstLevel && (
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", left: 0, bottom: "-22px" }}>
            <TreeBranch />
          </div>
        </div>
      )}

      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ display: "inline-block", marginRight: "10px" }}>
          <CheckboxField
            checked={isSelected}
            disabled={disabled || excludedCategory || excludedParentCategory}
            label={""}
            name={kind == "select" ? "category_selection" : "category_exclusion"}
            value={category.name || ""}
            onChange={(event) => (event.target.checked ? addCategory(category.id) : removeCategory(category.id))}
          />
        </span>
        {hasChilds && (
          <IconButton icon={shown ? "folder-opened" : "folder"} onClick={() => setShown((state) => !state)} />
        )}
        {hasChilds ? (
          <span
            style={{ cursor: "pointer", marginLeft: "10px", opacity: excludedCategory ? 0.4 : 1 }}
            onClick={() => setShown((state) => !state)}
          >
            {category.name}
          </span>
        ) : (
          <span style={{ marginLeft: "10px", opacity: excludedCategory ? 0.4 : 1 }}>{category.name}</span>
        )}
      </div>
      {shown && hasChilds && (
        <ul style={{ listStyle: "none", margin: 0, overflow: "hidden" }}>
          {childs.map((child) => (
            <CategoryLine
              key={child.id}
              addCategory={addCategory}
              categories={categories}
              category={child}
              disabled={disabled}
              kind={kind}
              removeCategory={removeCategory}
              selectedCategoryIds={selectedCategoryIds}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

type CategoryTreePropsT = {
  addCategory: (id: string) => void;
  categories?: NonNullable<OrganizationCategoriesQueryT["organization"]>["categories"];
  disabled?: boolean;
  kind: CategoryTreeKindT;
  removeCategory: (id: string) => void;
  selectedCategoryIds: string[];
};

export const CategoryTree = ({ categories, ...rest }: CategoryTreePropsT) => {
  return (
    <ul style={{ listStyle: "none", overflow: "hidden", padding: 0 }}>
      {(categories || [])
        .filter((category) => category.parentGuid === null)
        .map((category) => (
          <CategoryLine key={category.id} categories={categories} category={category} firstLevel {...rest} />
        ))}
    </ul>
  );
};

type CategoriesSelectionPropsT = {
  categories?: NonNullable<OrganizationCategoriesQueryT["organization"]>["categories"];
  disabled?: boolean;
};

const CategoriesSelection = ({ categories, disabled }: CategoriesSelectionPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Wizard Suggestion Generator Page" });
  const [isSaved, setIsSaved] = useState(false);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState(
    (categories || []).filter((category) => category.selected).map(({ id }) => id)
  );
  const currentSelectedIds = (categories || []).filter((category) => category.selected).map(({ id }) => id);

  const [selectCategories, { error: mutationError, loading: mutationLoading }] = useSelectCategoriesMutation({
    variables: { categoryIds: selectedCategoryIds },
    onCompleted: () => {
      // toast.success("Vybrané kategorie uloženy");
      setIsSaved(true);
      setTimeout(() => setIsSaved(false), 2000);
    },
  });

  const addCategory = useCallback(
    (id: string) => {
      const newState = [...selectedCategoryIds, id];
      setSelectedCategoryIds(newState);
    },
    [setSelectedCategoryIds, selectedCategoryIds]
  );
  const removeCategory = useCallback(
    (id: string) => {
      const newState = [...selectedCategoryIds].filter((catId) => catId !== id);
      setSelectedCategoryIds(newState);
    },
    [setSelectedCategoryIds, selectedCategoryIds]
  );

  return (
    <>
      <div style={{ border: "1px solid #E9E9E9", margin: "20px 0", padding: "10px", background: "white" }}>
        <CategoryTree
          addCategory={addCategory}
          categories={categories}
          disabled={disabled}
          kind="select"
          removeCategory={removeCategory}
          selectedCategoryIds={selectedCategoryIds}
        />
      </div>
      <div
        data-test-id="category-selection-result"
        style={{ border: "1px solid #E9E9E9", padding: "24px", textAlign: "center", color: "#37373D" }}
      >
        {t("selected")}{" "}
        <strong>
          {selectedCategoryIds.length} {t("categories")}
        </strong>
      </div>
      {(JSON.stringify(currentSelectedIds.sort()) === JSON.stringify(selectedCategoryIds.sort()) &&
        !mutationLoading &&
        !isSaved && <span />) || (
        <Button
          data-test-id="save-category-selection"
          style={{ marginTop: "10px" }}
          variant="action"
          onClick={() => selectCategories()}
        >
          {(isSaved && (
            <span style={{ display: "flex", alignItems: "center" }}>
              <CheckmarkIcon /> &nbsp; {t("Saved")}
            </span>
          )) ||
            (mutationLoading && t("Saving")) ||
            t("Save")}
        </Button>
      )}
      {mutationError && (
        <SystemMessage description={t("there was a problem saving category selection")} level="error" />
      )}
    </>
  );
};

type CategorySelectionWrapperPropsT = {
  disabled?: boolean;
};
export const CategorySelectionWrapper = ({ disabled }: CategorySelectionWrapperPropsT) => {
  const { data, loading } = useOrganizationCategoriesQuery();
  if (loading) {
    return <SmallLoader />;
  }

  return <CategoriesSelection categories={data?.organization?.categories} disabled={disabled} />;
};
