import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useAuditLogsQuery } from "../../graphql/generated/graphql";
import { formatTime } from "../../i18n/formatDate";
import { PaginateNext, PaginatePrevious, PaginationContainer } from "../AdPreview/Pagination";
import { ImageSuggestion, UploadedImageSuggestion } from "../ImageAssetLines/ImageSuggestion";
import { SmallLoader } from "../SmallLoader";

const limit = 10;

export const AuditLogs = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Audit Log Page" });
  const [page, setPage] = useState<number>(1);

  const { data, loading } = useAuditLogsQuery({
    variables: {
      limit,
      page,
      dateTo: new Date().toISOString().split("T")[0],
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <SmallLoader />;
  }

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>{t("table header.date")}</TableHeader>
            <TableHeader>{t("table header.change")}</TableHeader>
            <TableHeader>{t("table header.user")}</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.organization?.auditLogs.map((auditLog) => (
            <TableRow key={auditLog.id}>
              <TableCell>{formatTime(auditLog.createdAt)}</TableCell>
              <TableCell>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span dangerouslySetInnerHTML={{ __html: auditLog.message }} />
                  {(auditLog.assetLine?.imageSuggestion || auditLog.assetLine?.uploadedImage) && (
                    <div className="AuditLog-image">
                      {auditLog.assetLine?.imageSuggestion && (
                        <ImageSuggestion scale={0.5} {...auditLog.assetLine.imageSuggestion} />
                      )}
                      {auditLog.assetLine?.uploadedImage && (
                        <UploadedImageSuggestion {...auditLog.assetLine.uploadedImage} />
                      )}
                    </div>
                  )}
                </div>
              </TableCell>
              <TableCell>{auditLog.userEmail}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {!(page === 1 && (data?.organization?.auditLogs.length || 0) < limit) && (
        <PaginationContainer>
          <PaginatePrevious disabled={page === 1} onClick={() => setPage((state) => Math.max(state - 1, 1))} />
          <PaginateNext
            disabled={(data?.organization?.auditLogs.length || 0) < limit}
            onClick={() => setPage((state) => state + 1)}
          />
        </PaginationContainer>
      )}
    </div>
  );
};
