import React, { ReactNode } from "react";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import video from "../../assets/loading-animation.mp4";
import { Credits } from "../../elements/Credits";
import {
  StepperIconPropsT,
  VerticalCreditStepIcon,
  VerticalDoneStepIcon,
  VerticalSettingsStepIcon,
} from "../../elements/ProgressStepper/Icons";
import { WizardStepT, useCreditsQuery, useStartSynchronizationMutation } from "../../graphql/generated/graphql";
import { useSetWizardStepEffect } from "./useSetWizardStepEffect";

type StepPropsT = {
  Icon: (props: StepperIconPropsT) => JSX.Element;
  children?: ReactNode;
  done?: boolean;
  hasNext?: boolean;
  header: string;
  text: string;
};
const Step = ({ Icon, children, done, hasNext, header, text }: StepPropsT) => {
  return (
    <div style={{ color: "#37373D", display: "flex", flexDirection: "row", gap: "16px" }}>
      <div style={{ position: "relative" }}>
        {hasNext && (
          <div
            style={{ position: "absolute", background: "#E9E9E9", width: "1px", height: "100%", left: "50%", top: 0 }}
          />
        )}
        <div
          style={{
            width: "44px",
            height: "44px",
            background: (done && "#9DCA57") || "#14B1EF",
            border: "10px solid white",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "17px",
              width: "17px",
            }}
          >
            {done ? <VerticalDoneStepIcon /> : <Icon color={done ? "#FFFFFF" : "#BBBBBB"} />}
          </div>
        </div>
      </div>
      <div>
        <h3 style={{ marginTop: "10px" }}>{header}</h3>
        <p>{text}</p>
        {children}
      </div>
    </div>
  );
};

export const Finish = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Wizard Finish Page" });
  const { data } = useCreditsQuery();
  const [startSynchronization, {}] = useStartSynchronizationMutation();

  useSetWizardStepEffect({ wizardStep: WizardStepT.FinishT });
  const navigate = useNavigate();
  const zeroCredits = (data?.organization?.creditBalance?.amount || 0) <= 0;

  return (
    <div>
      <h1>{t("Headline")}</h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          gap: "16px",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <video src={video} width="390" autoPlay loop playsInline />

        <div style={{ maxWidth: "463px", marginTop: "48px" }}>
          <Step header={t("step 1.header")} Icon={VerticalCreditStepIcon} text={t("step 1.text")} done hasNext />
          <Step
            done={!zeroCredits}
            header={t("step 2.header")}
            Icon={VerticalCreditStepIcon}
            text={t("step 2.text")}
            hasNext
          >
            <div className="v2form">
              <Credits fullWidth />
            </div>
          </Step>
          <Step header={t("step 3.header")} Icon={VerticalSettingsStepIcon} text={t("step 3.text")}>
            <Button
              data-test-id="start-campaign"
              disabled={zeroCredits}
              onClick={() => startSynchronization().then(() => navigate("/dashboard"))}
            >
              {t("Start campaign")}
            </Button>
          </Step>
        </div>
      </div>
    </div>
  );
};
