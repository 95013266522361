import React, { ReactNode } from "react";

type GraphTabsWrapperPropsT = {
  children: ReactNode;
  tabsCount: number;
};
export const GraphTabWrapper = ({ children, tabsCount }: GraphTabsWrapperPropsT) => {
  return (
    <div className="tabs-dashboard ui-tabs ajax-tabs std-tabs ui-widget ui-widget-content ui-corner-all">
      <ul
        className={`list-columns-${tabsCount} ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all`}
        role="tablist"
        style={{ display: "flex" }}
      >
        {children}
      </ul>
    </div>
  );
};
type GraphTabPropsT = {
  active?: boolean;
  additional?: string;
  header?: string;
  main?: string;
  onClick: () => void;
};
export const GraphTab = ({ active, additional, header, main, onClick }: GraphTabPropsT) => {
  const isActive = active;
  return (
    <li
      aria-controls="uitab-settings"
      aria-selected={isActive}
      className={`ui-state-default ui-corner-top ${isActive ? "ui-tabs-active ui-state-active" : ""}`}
      role="tab"
      style={{ flexGrow: 1 }}
      tabIndex={isActive ? -1 : 0}
    >
      <a className="ui-tabs-anchor" href="#" role="presentation" tabIndex={-1} onClick={onClick}>
        {header && <span className="tab-content-header">{header}</span>}
        {main && <span className="tab-content-main">{main}</span>}
        {additional && <span className="tab-content-additional">{additional}</span>}
      </a>
    </li>
  );
};
