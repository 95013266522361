import React, { ChangeEvent, useState } from "react";
import { Input } from "@shoptet/ui";
import { formatCurrencyUnit } from "../i18n/formatNumbers";

interface CurrencyInputProps {
  currency?: string;
  error?: boolean;
  errorText?: string;
  name: string;
  onChange: (value: string) => void;
  value: string;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({ currency, error = false, errorText, name, onChange, value }) => {
  const [inputValue, setInputValue] = useState<string>(value);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const numberValue = parseFloat(rawValue.replace(/[^0-9.,]/g, "").replace(",", "."));

    if (isNaN(numberValue) || numberValue < 0) {
      setInputValue(rawValue);
      onChange("");
      return;
    }

    setInputValue(rawValue);
    onChange(numberValue.toString());
  };

  const handleBlur = () => {
    const numberValue = parseFloat(inputValue.replace(/[^0-9.-]/g, ""));

    if (isNaN(numberValue) || numberValue < 0) {
      setInputValue("");
      onChange("");
      return;
    }

    const formattedValue =
      inputValue.includes(".") || inputValue.includes(",") ? numberValue.toFixed(2) : numberValue.toString();

    setInputValue(formattedValue);
    onChange(formattedValue);
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Input
          name={name}
          type="text"
          value={inputValue}
          style={{
            display: "block",
            width: "100%",
            textAlign: "right",
            border: error ? "1px solid #ff0000" : "1px solid #cfcfcf",
          }}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {currency && <p style={{ margin: "0 0 0 8px" }}>{formatCurrencyUnit(currency)}</p>}
      </div>
      {errorText && error && <p style={{ color: "#ff0000", margin: "4px 0 0 0" }}>{errorText}</p>}
    </div>
  );
};

export default CurrencyInput;
