import React, { useState } from "react";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AssetPageHeader } from "../elements/AssetsPageHeader";
import { InfoBox } from "../elements/InfoBox";
import { SmallLoader } from "../elements/SmallLoader";
import { TextAssetLines } from "../elements/TextAssetLines";
import { useGenerateTextSuggestions, useTextAssetLines } from "../elements/TextAssetLines/useTextAssetLines";
import {
  AssetLineKindT,
  SuggestionKindT,
  useDiscardDraftAssetLinesMutation,
  usePublishDraftAssetLinesMutation,
} from "../graphql/generated/graphql";

type EditTextAssetsPropsT = {
  kind: SuggestionKindT;
};

export const EditTextAssets = ({ kind }: EditTextAssetsPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Assets Page" });
  const navigate = useNavigate();
  const { activeTextAssetLines, loadingData, validateTexts } = useTextAssetLines(true);
  const { data, generateMoreSuggestions, generatingMoreSuggestions, loading } = useGenerateTextSuggestions(kind);
  const [refreshComponent, setRefreshComponent] = useState(1);

  const [publish, { loading: loadingPublish }] = usePublishDraftAssetLinesMutation({
    variables: { kinds: [kind as string as AssetLineKindT] },
    onCompleted: () => navigate("/assets"),
  });
  const [discard, { loading: loadingDiscard }] = useDiscardDraftAssetLinesMutation({
    variables: { kinds: [kind as string as AssetLineKindT] },
  });

  const errors = (validateTexts?.errors || []).filter((error) => (error.attribute as string) === (kind as string));
  const hasAnyDraft = !!activeTextAssetLines?.find(
    (line) => (line.kind as string) === (kind as string) && line.draftState
  );
  return (
    <div>
      <AssetPageHeader />
      <div style={{ height: "33px" }} />
      <InfoBox>{t("InfoBox-Do not update too often")}</InfoBox>
      {loading || loadingData ? (
        <SmallLoader />
      ) : (
        <>
          <TextAssetLines
            key={`${kind}-${refreshComponent}`}
            activeTextAssetLines={activeTextAssetLines || []}
            errors={validateTexts?.errors || []}
            generateMoreSuggestions={generateMoreSuggestions}
            generatingMoreSuggestions={generatingMoreSuggestions}
            kind={kind}
            textSuggestions={data?.organization?.textSuggestions || []}
            draft
            hideSubtext
          />
          <br />
          {hasAnyDraft ? (
            <>
              <Button
                disabled={loadingPublish || errors.length > 0}
                style={{ marginRight: "10px" }}
                onClick={() => publish()}
              >
                {loadingPublish ? t("publishing") : t("publish changes")}
              </Button>
              <Button
                disabled={loadingDiscard}
                variant="muted"
                onClick={() => discard().then(() => setRefreshComponent((state) => state + 1))}
              >
                {loadingDiscard ? t("reverting changes") : t("revert changes")}
              </Button>
            </>
          ) : (
            <Button disabled>{t("publish changes")}</Button>
          )}
        </>
      )}
    </div>
  );
};
