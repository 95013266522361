import React, { CSSProperties, ReactNode } from "react";

type InfoBoxPropsT = {
  children: ReactNode;
  style?: CSSProperties;
};
export const InfoBox = ({ children, ...rest }: InfoBoxPropsT) => (
  <div {...rest} className="perexBox">
    <div>{children}</div>
  </div>
);

export const WarningBox = ({ children, style, ...rest }: InfoBoxPropsT) => (
  <p {...rest} style={{ padding: "25px 16px", color: "#db1b4e", margin: "15px 0px", background: "#FFF2F2", ...style }}>
    {children}
  </p>
);
