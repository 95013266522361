import React from "react";
import { Trans, useTranslation } from "react-i18next";
import video from "../assets/loading-animation.mp4";
import { Icon } from "../elements/Icons";
import { RemainingIndicator } from "../elements/RemainingIndicator";
import { OrganizationNotReadyStepsT } from "../graphql/generated/graphql";
import { formatInteger, formatPercents } from "../i18n/formatNumbers";

const TRANSLATION_KEYS = {
  [OrganizationNotReadyStepsT.CategoriesT]: "Preparing categories",
  [OrganizationNotReadyStepsT.ProductsT]: "Preparing products",
  [OrganizationNotReadyStepsT.DesignT]: "Preparing design colors, logs and additional information",
};

type StepPropsT = {
  categoriesCount: number;
  done?: boolean;
  productFlagsCount: number;
  productsCount: number;
  productsCountToSynchronize: number;
  step: OrganizationNotReadyStepsT;
};
const Step = ({
  categoriesCount,
  done,
  productFlagsCount,
  productsCount,
  productsCountToSynchronize,
  step,
}: StepPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Getting Ready Page" });
  return (
    <li style={{ margin: "3px 0" }}>
      {done ? <Icon kind="accomplish-item" /> : <Icon kind="loader" />}
      <span style={{ marginLeft: "8px" }}>
        {t(TRANSLATION_KEYS[step], {
          productsCount: formatInteger(productsCount),
          categoriesCount: formatInteger(categoriesCount),
          productFlagsCount: formatInteger(productFlagsCount),
        })}
      </span>
      {step === OrganizationNotReadyStepsT.ProductsT && !done && productsCountToSynchronize > 0 && (
        <div
          style={{
            display: "inline-block",
            margin: "6px 0 8px 32px",
            padding: "4px 8px",
            border: "1px solid #E9E9E9",
            background: "#F9F9F9",
            borderRadius: "4px",
          }}
        >
          <Trans
            components={{ b: <b />, strong: <b style={{ color: "#14B1EF", whiteSpace: "nowrap" }} /> }}
            i18nKey="Synchronizing products status"
            t={t}
            values={{
              productsCount: formatInteger(productsCount),
              productsCountToSynchronize: formatInteger(productsCountToSynchronize),
              synchronizedProductsCountPercent: formatPercents(
                Math.floor((productsCount * 100) / productsCountToSynchronize)
              ),
            }}
          />
        </div>
      )}
    </li>
  );
};

type NotReadyPropsT = {
  categoriesCount: number;
  notReadySteps: OrganizationNotReadyStepsT[];
  productFlagsCount: number;
  productsCount: number;
  productsCountToSynchronize: number;
};
export const NotReady = ({
  categoriesCount,
  notReadySteps,
  productFlagsCount,
  productsCount,
  productsCountToSynchronize,
}: NotReadyPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Getting Ready Page" });
  return (
    <div>
      <h1>{t("Headline")}</h1>
      <div style={{ width: "390px", marginInline: "auto" }}>
        <video src={video} width="390" autoPlay loop playsInline />
        <br />
        <h3 style={{ textAlign: "center" }}>{t("Sub headline")}</h3>
        <div style={{ textAlign: "center", fontSize: "12px", lineHeight: "16px", margin: "20px 0px" }}>
          {t("Sub text")}
        </div>
        <ul style={{ listStyle: "none", padding: "24px", border: "1px solid #E9E9E9" }}>
          {Object.values(OrganizationNotReadyStepsT).map((step) => (
            <Step
              key={step}
              categoriesCount={categoriesCount}
              done={notReadySteps.indexOf(step) === -1}
              productFlagsCount={productFlagsCount}
              productsCount={productsCount}
              productsCountToSynchronize={productsCountToSynchronize}
              step={step}
            />
          ))}
        </ul>
        <RemainingIndicator defaultSeconds={30} />
      </div>
    </div>
  );
};
