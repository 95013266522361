import React from "react";
import { useTranslation } from "react-i18next";
import { CampaignState } from "../elements/CampaignState";
import { Credits } from "../elements/Credits";
import { GmcIssues } from "../elements/GmcIssues";
import { PerformanceStats } from "../elements/PerformanceStats";
import { SummaryStats } from "../elements/SummaryStats";
import { TabNavigation } from "../elements/TabNavigation";
import { WizardStepT } from "../graphql/generated/graphql";
import { useSetWizardStepEffect } from "./wizard/useSetWizardStepEffect";

export const Dashboard = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Dashboard Page" });

  useSetWizardStepEffect({ wizardStep: WizardStepT.DashboardT });
  return (
    <div>
      <h1 data-test-id="dashboard-page">{t("Headline")}</h1>
      <CampaignState />
      <TabNavigation />
      <Credits showInfo />

      <h2>{t("Stats Headline")}</h2>
      <h3>{t("Stats Summary Headline")}</h3>
      <SummaryStats />
      <h3>{t("Stats Detail Headline")}</h3>
      <PerformanceStats />

      <h2>{t("Recommendation Headline")}</h2>
      <GmcIssues />
    </div>
  );
};
