import React, { useState } from "react";
import { Button } from "@shoptet/ui";
import { Trans, useTranslation } from "react-i18next";
import { CurrencyT, useDecreaseCreditMutation } from "../../graphql/generated/graphql";
import FieldSet from "../../ui/FieldSet/FieldSet";
import CurrencyInput from "../CurrencyInput";
import { InfoBox } from "../InfoBox";
import { ModalWrapper } from "../ModalWrapper";

type CreditModalDecreasePropsT = {
  creditBalance?: number | null;
  onClose: () => void;
  updateCreditAmount: () => void;
};

const CreditDecrease = ({ creditBalance, onClose, updateCreditAmount }: CreditModalDecreasePropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.Credits" });
  const [decreaseAmount, setDecreaseAmount] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const [state] = useState({
    currency: CurrencyT.CzkT,
    creditAmount: "500",
  });
  const currency = state.currency;

  const [decreaseCredit] = useDecreaseCreditMutation({
    onCompleted() {
      updateCreditAmount();
      setIsSuccess(true);
    },
  });

  const handleChange = (value: string) => {
    setDecreaseAmount(value);
  };

  const handleSend = () => {
    decreaseCredit({
      variables: {
        amount: parseFloat(decreaseAmount),
        currency: currency,
      },
    });
  };

  const handleSendAll = () => {
    if (creditBalance !== null && creditBalance !== undefined) {
      decreaseCredit({
        variables: {
          amount: creditBalance,
          currency: currency,
        },
      });
    }
  };

  const isInputValid =
    decreaseAmount === "" ||
    (creditBalance !== null && creditBalance !== undefined && parseFloat(decreaseAmount) <= creditBalance);
  const isFormValid =
    decreaseAmount !== "" &&
    creditBalance !== null &&
    creditBalance !== undefined &&
    parseFloat(decreaseAmount) <= creditBalance &&
    parseFloat(decreaseAmount) > 0;
  const errorText = isInputValid ? "" : t("The amount is higher than the credit balance");

  return (
    <>
      {!isSuccess && (
        <div>
          <p style={{ margin: "0.75em 0 3em" }}>{t("Decrease info box")}</p>
          <FieldSet title={t("Full balance")}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <p style={{ margin: 0 }}>
                <Trans
                  components={{ strong: <strong /> }}
                  i18nKey="Return all balance warning"
                  t={t}
                  values={{ creditBalance }}
                />
              </p>
              <Button onClick={handleSendAll}>{t("Decrease all credit")}</Button>
            </div>
          </FieldSet>
          <div style={{ margin: "2em 0" }}>
            <FieldSet title={t("Own amount")}>
              <div
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingLeft: "35px" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ margin: 0 }}>{t("Enter the amount of credits")}</p>
                  <div style={{ width: "243px", margin: "0 8px 0 16px" }}>
                    <CurrencyInput
                      currency={currency}
                      error={!isInputValid}
                      errorText={errorText}
                      name="credit_amount"
                      value={decreaseAmount}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <Button data-test-id="withdraw-part-credit" disabled={!isFormValid} onClick={handleSend}>
                  {t("Decrease part credit")}
                </Button>
              </div>
            </FieldSet>
          </div>
        </div>
      )}

      {isSuccess && (
        <div>
          <p style={{ margin: "8px 0 24px" }}>{t("Decrease successfully requested")}</p>
          <InfoBox>
            <p style={{ margin: "0" }}>{t("Decrease successfully requested info")}</p>
          </InfoBox>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "24px" }}>
            <Button variant="muted" onClick={onClose}>
              {t("Decrease close")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export const CreditModalDecrease = ({
  creditBalance,
  isOpen,
  onClose,
  updateCreditAmount,
}: { isOpen: boolean } & CreditModalDecreasePropsT) => {
  return (
    <ModalWrapper isOpen={isOpen} title="Vrácení kreditu" onClose={onClose}>
      {isOpen && (
        <CreditDecrease creditBalance={creditBalance} updateCreditAmount={updateCreditAmount} onClose={onClose} />
      )}
    </ModalWrapper>
  );
};
