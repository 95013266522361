import React, { memo } from "react";
import { ImageSuggestionKindT } from "../../graphql/generated/graphql";

type EmbeddPropsT = {
  height: number;
  scale?: number;
  src: string;
  width: number;
};

const Embed = memo(({ height, scale, src, width }: EmbeddPropsT) => {
  return (
    <div>
      <embed src={src} style={{ transform: `scale(${scale})`, width: `${width}px`, height: `${height}px` }} />
    </div>
  );
});

type ImageSuggestionPropsT = {
  height: number;
  kind: ImageSuggestionKindT;
  scale?: number;
  svgLink: string;
  width: number;
};
export const ImageSuggestion = ({ height, kind, scale, svgLink, width }: ImageSuggestionPropsT) => {
  const givenScale = scale || 1;
  const scaleImage =
    ((kind === ImageSuggestionKindT.MarketingImageT ? 286 : 200) * givenScale) / Math.max(height, width);
  return (
    <div style={{ border: "1px solid #eee", display: "inline-block" }}>
      <div className={`ImageWrapper--${kind}`}>
        <Embed height={height} scale={scaleImage} src={svgLink} width={width} />
      </div>
    </div>
  );
};

type UploadedImageSuggestionPropsT = {
  croppedImageUrl: string;
  kind: ImageSuggestionKindT;
};
export const UploadedImageSuggestion = ({ croppedImageUrl, kind }: UploadedImageSuggestionPropsT) => {
  return (
    <div style={{ border: "1px solid #eee", display: "inline-block" }}>
      <div className={`ImageWrapper--${kind}`}>
        <img src={croppedImageUrl} />
      </div>
    </div>
  );
};

type ImageSuggestionPreviewPropsT = {
  height: number;
  maxWidth: number;
  svgLink: string;
  width: number;
};
export const ImageSuggestionPreview = ({ height, maxWidth, svgLink, width }: ImageSuggestionPreviewPropsT) => {
  const scale = maxWidth / width;
  const maxHeight = height * scale;
  return (
    <div>
      <div className="ImageWrapper--Preview" style={{ width: `${maxWidth}px`, height: `${maxHeight}px` }}>
        <Embed height={height} scale={scale} src={svgLink} width={width} />
      </div>
    </div>
  );
};

type UploadedImageSuggestionPreviewPropsT = {
  cropHeight: number;
  cropWidth: number;
  croppedImageUrl: string;
  maxWidth: number;
};
export const UploadedImageSuggestionPreview = ({
  cropHeight,
  cropWidth,
  croppedImageUrl,
  maxWidth,
}: UploadedImageSuggestionPreviewPropsT) => {
  const scale = maxWidth / cropWidth;
  const maxHeight = cropHeight * scale;
  return <img src={croppedImageUrl} style={{ width: `${maxWidth}px`, height: `${maxHeight}px` }} />;
};
