import { useEffect } from "react";
import { IFramePage } from "iframe-resizer";
import { useLocation } from "react-router-dom";

declare global {
  interface Window {
    parentIFrame: IFramePage & { reset: () => void };
  }
}

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
    window.parentIFrame?.reset();
    window.parentIFrame?.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default ScrollToTop;
