import React, { ReactNode } from "react";
import { Button } from "@shoptet/ui";
import { Link, useLocation, useNavigate } from "react-router-dom";

type TabsWrapperPropsT = {
  children: ReactNode;
};
export const TabWrapper = ({ children }: TabsWrapperPropsT) => {
  return (
    <div className="tabs ui-tabs ui-widget ui-widget-content ui-corner-all">
      <ul
        className="ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all dropdown-ready"
        role="tablist"
      >
        {children}
      </ul>
    </div>
  );
};
type TabPropsT = {
  active?: boolean;
  children: ReactNode;
  exactMatch?: boolean;
  to: string;
};
export const Tab = ({ active, children, exactMatch, to, ...rest }: TabPropsT) => {
  const { pathname } = useLocation();

  const isActive = active || (exactMatch ? pathname === to : pathname.startsWith(to));

  return (
    <li
      {...rest}
      aria-controls="uitab-settings"
      aria-selected={isActive}
      className={`ui-state-default ui-corner-top ${isActive ? "ui-tabs-active ui-state-active" : ""}`}
      role="tab"
      tabIndex={isActive ? -1 : 0}
    >
      <Link className="ui-tabs-anchor" role="presentation" tabIndex={-1} to={to}>
        {children}
      </Link>
    </li>
  );
};

type StateTabPropsT = {
  children: ReactNode;
  currentPage: string;
  exactMatch?: boolean;
  page: string;
  setPage: (v: string) => void;
};
export const StateTab = ({ children, currentPage, exactMatch, page, setPage, ...rest }: StateTabPropsT) => {
  const isActive = currentPage === page;

  return (
    <li
      {...rest}
      aria-controls="uitab-settings"
      aria-selected={isActive}
      className={`ui-state-default ui-corner-top ${isActive ? "ui-tabs-active ui-state-active" : ""}`}
      role="tab"
      tabIndex={isActive ? -1 : 0}
    >
      <a
        className="ui-tabs-anchor"
        data-test-id={`tab-nav-${page}`}
        href={`#${page}`}
        role="presentation"
        tabIndex={-1}
        onClick={() => setPage(page)}
      >
        {children}
      </a>
    </li>
  );
};

export const ButtonTab = ({ active, children, exactMatch, to, ...rest }: TabPropsT) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isActive = active || (exactMatch ? pathname === to : pathname.startsWith(to));

  return (
    <Button
      {...rest}
      aria-controls="uitab-settings"
      aria-selected={isActive}
      role="tab"
      style={{ marginRight: "10px", marginBottom: "10px" }}
      tabIndex={isActive ? -1 : 0}
      variant={isActive ? "default" : "muted"}
      onClick={() => navigate(to)}
    >
      {children}
    </Button>
  );
};
