import React from "react";
import { useTranslation } from "react-i18next";
import { CampaignState } from "../elements/CampaignState";
import { Credits } from "../elements/Credits";
import { CreditTransactions } from "../elements/CreditTransactions";
import { HistoryNavigation } from "../elements/HistoryNavigation";
import { TabNavigation } from "../elements/TabNavigation";

export const History = () => {
  const { t } = useTranslation("translation", { keyPrefix: "History Page" });
  return (
    <div>
      <h1>{t("Headline")}</h1>
      <CampaignState />
      <TabNavigation />
      <HistoryNavigation />
      <Credits showInfo />
      <h2 style={{ marginBottom: "32px" }}>{t("Credit Transaction Headline")}</h2>
      <CreditTransactions />
    </div>
  );
};
