import { useState } from "react";
import { Button } from "@shoptet/ui";
import { Trans, useTranslation } from "react-i18next";
import { useCreditsQuery } from "../../graphql/generated/graphql";
import { formatCurrencyUnit, formatMoney } from "../../i18n/formatNumbers";
import { PlusIcon } from "../Icons";
import { SmallLoader } from "../SmallLoader";
import { CreditModal } from "./Modal";

type CreditPropsT = {
  fullWidth?: boolean;
  showInfo?: boolean;
};
export const Credits = ({ fullWidth, showInfo }: CreditPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.Credits" });
  const [modalOpened, setModalOpened] = useState(false);
  const [creditModalCounter, setCreditModalCounter] = useState(0);

  const { data, loading } = useCreditsQuery();
  if (loading || !data?.organization) {
    return <SmallLoader />;
  }

  const hasBoughtAnyCredits = (data.organization.boughtCredits.amount || 0) > 0;
  if (loading || !data?.organization) {
    return <SmallLoader />;
  }

  return (
    <>
      {showInfo && (
        <>
          <h2>{t("Current credit balance Headline")}</h2>
        </>
      )}
      <div>
        <div style={fullWidth ? {} : { justifyContent: "center", display: "flex" }}>
          <div
            style={{
              border: "1px solid #E9E9E9",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: "267px",
              position: "relative",
              padding: "10px",
              margin: "20px 0",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-10px",
                left: "50%",
                transform: "translateX(-50%)",
                color: "#37373D",
                background: "white",
                padding: "0px 10px",
              }}
            >
              {t("BoxLabel")}
            </div>
            <div
              data-test-id="current-credit-balance"
              style={{ textAlign: "center", display: "flex", alignItems: "center", margin: "10px 0" }}
            >
              <span style={{ color: "#14B1EF", fontSize: "24px", fontWeight: 700 }}>
                {formatMoney({ amount: data.organization.creditBalance.amount || 0 })}
              </span>
              <span style={{ color: "#BBBBBB", paddingLeft: "5px" }}>
                {data.organization.creditBalance.currency
                  ? formatCurrencyUnit(data.organization.creditBalance.currency)
                  : ""}
              </span>
            </div>
            {showInfo && data.organization.remainingCreditDays > 7 && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ color: "#9DCA57" }}>
                  <Trans
                    components={{ strong: <strong /> }}
                    i18nKey="Credit will run out in"
                    t={t}
                    values={{ remainingCreditDays: data.organization.remainingCreditDays }}
                  />
                </div>
              </div>
            )}
            {showInfo && hasBoughtAnyCredits && data.organization.remainingCreditDays <= 7 && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ color: "#db1b4e" }}>
                  <Trans
                    components={{ strong: <strong /> }}
                    i18nKey="Credit will suffice for"
                    t={t}
                    values={{ remainingCreditDays: data.organization.remainingCreditDays }}
                  />
                </div>
              </div>
            )}
            <Button
              data-test-id="credit-onetime-increase-button"
              style={{ margin: "10px 0" }}
              variant={"action"}
              onClick={() => setModalOpened(true)}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <PlusIcon /> &nbsp; {t("Increase credits button")}
              </span>
            </Button>
          </div>
        </div>
        <CreditModal
          key={`credit-modal-${creditModalCounter}`}
          isOpen={modalOpened}
          onClose={() => {
            setModalOpened(false);
            setCreditModalCounter((counter) => counter + 1);
          }}
        />
      </div>
    </>
  );
};
