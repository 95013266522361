import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  LastSuggestionRequestQueryT,
  SuggestionKindT,
  useActiveTextsAssetLinesQuery,
  useLastSuggestionRequestQuery,
  useNotDeletedTextsAssetLinesQuery,
  useOrganizationValidateTextsQuery,
  useRequestAdditionalSuggestionMutation,
  useTextSuggestionsQuery,
} from "../../graphql/generated/graphql";
import { usePrevious } from "../../hooks/usePrevious";

const fetchData = (kind: SuggestionKindT, data?: LastSuggestionRequestQueryT) => {
  if (kind === SuggestionKindT.ShortHeadlineT) {
    return data?.organization?.lastSuggestionRequestsShortHeadline?.[0];
  }
  if (kind === SuggestionKindT.LongHeadlineT) {
    return data?.organization?.lastSuggestionRequestsLongHeadline?.[0];
  }
  if (kind === SuggestionKindT.ShortDescriptionT) {
    return data?.organization?.lastSuggestionRequestsShortDescription?.[0];
  }

  return data?.organization?.lastSuggestionRequestsLongDescription?.[0];
};

export const useGenerateTextSuggestions = (kind: SuggestionKindT) => {
  const [generatingMoreSuggestions, setGeneratingMoreSuggestions] = useState(true);
  const [polling, setPolling] = useState(false);
  const previousPolling = usePrevious(polling);

  const { data, loading, refetch } = useTextSuggestionsQuery({
    variables: { kind, limit: 1000 },
    fetchPolicy: "network-only",
  });

  const {
    data: lastSuggestionRequestData,
    startPolling,
    stopPolling,
  } = useLastSuggestionRequestQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (response) => {
      const previousPollingState = polling;
      if (!fetchData(kind, response)?.inProgress) {
        setPolling(false);
        if (previousPollingState) {
          refetch().then(() => {
            setGeneratingMoreSuggestions(false);
          });
        } else if (generatingMoreSuggestions) {
          setGeneratingMoreSuggestions(false);
        }
      }

      if (fetchData(kind, response)?.inProgress && !previousPollingState) {
        setPolling(true);
      }
    },
  });

  useEffect(() => {
    if (!previousPolling && polling) {
      setTimeout(() => startPolling(1000), 500);
    }
    if (previousPolling && !polling) {
      stopPolling();
    }
  }, [startPolling, stopPolling, polling, previousPolling]);

  useEffect(() => () => stopPolling(), [stopPolling]);

  const [requestAdditionalSuggestions] = useRequestAdditionalSuggestionMutation();

  const generateMoreSuggestions = () => {
    if (generatingMoreSuggestions) {
      return;
    }
    const id = fetchData(kind, lastSuggestionRequestData)?.id;
    if (id) {
      setGeneratingMoreSuggestions(true);
      requestAdditionalSuggestions({
        variables: {
          suggestionRequestId: id,
        },
      }).then(() => {
        setPolling(true);
      });
    } else {
      toast.error("Nemůžeme vygenerovat další návrhy protože jste neprovedli první generování návrhů");
    }
  };

  return {
    generatingMoreSuggestions,
    data,
    loading,
    generateMoreSuggestions,
  };
};

export const useTextAssetLines = (draft = false) => {
  const { data: dataActiveTextValidation } = useOrganizationValidateTextsQuery();
  const { data: dataActiveTextAssetLines, loading: loadingActive } = useActiveTextsAssetLinesQuery({ skip: draft });
  const { data: dataNotDeletedTextAssetLines, loading: loadingNotDeleted } = useNotDeletedTextsAssetLinesQuery({
    skip: !draft,
  });

  const activeTextAssetLines = draft
    ? dataNotDeletedTextAssetLines?.organization?.notDeletedTextsAssetLines
    : dataActiveTextAssetLines?.organization?.activeTextsAssetLines;
  const loadingData = draft ? loadingNotDeleted : loadingActive;

  const validateTexts = draft
    ? dataActiveTextValidation?.organization?.validateDraftTexts
    : dataActiveTextValidation?.organization?.validateTexts;

  return {
    validateTexts,
    activeTextAssetLines,
    loadingData,
  };
};
