import React, { useState } from "react";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BackIcon, Icon } from "../../elements/Icons";
import { InfoBox } from "../../elements/InfoBox";
import { ProgressStepper, StepsT } from "../../elements/ProgressStepper";
import { RemainingIndicator } from "../../elements/RemainingIndicator";
import { SmallLoader } from "../../elements/SmallLoader";
import { SuggestionInputForm } from "../../elements/SuggestionInputForm";
import { WizardStepT, useLastSuggestionRequestQuery } from "../../graphql/generated/graphql";
import { useSetWizardStepEffect } from "./useSetWizardStepEffect";

export const SuggestionGenerator = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Wizard Suggestion Generator Page" });
  useSetWizardStepEffect({ wizardStep: WizardStepT.SuggestionGeneratorT });
  const navigate = useNavigate();
  const [started, setStarted] = useState(false);
  const { data: lastSuggestionRequestData, loading: lastSuggestionRequestLoading } = useLastSuggestionRequestQuery({
    fetchPolicy: "network-only",
  });

  if (lastSuggestionRequestLoading) {
    return (
      <div>
        <h1>{t("Headline")}</h1>
        <ProgressStepper currentStep={StepsT.STEP_SUGGESTION} />
        <SmallLoader />
      </div>
    );
  }

  return (
    <div>
      <h1>{t("Headline")}</h1>
      <ProgressStepper currentStep={StepsT.STEP_SUGGESTION} />
      <div style={{ display: started ? "none" : "block" }}>
        <InfoBox>{t("InfoBox")}</InfoBox>
        <h2>{t("Sub Headline")}</h2>
        <SuggestionInputForm
          category={lastSuggestionRequestData?.organization?.category}
          defaultUserInput={
            lastSuggestionRequestData?.organization?.lastSuggestionRequestsShortHeadline?.[0]?.userInput
          }
          onComplete={() => navigate("/wizard/image-selection")}
          onStart={() => setStarted(true)}
        >
          {" "}
          <Button data-test-id="wizard-back" variant="muted" onClick={() => navigate("/wizard")}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <BackIcon />
              <span>&nbsp; {t("back")}</span>
            </span>
          </Button>
        </SuggestionInputForm>
      </div>
      {started && (
        <div style={{ height: "90ch", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ textAlign: "center" }}>
            <Icon kind="loader" />
            <br />
            {t("generation started text")}
          </div>
        </div>
      )}

      {started && <RemainingIndicator defaultSeconds={30} />}
      <br />
      <br />
    </div>
  );
};
