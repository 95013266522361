import React from "react";

const Loading: React.FC = () => {
  return (
    <div className="page-loader" style={{ display: "block" }}>
      <svg className="registration-circular" height="100" width="100">
        <circle
          className="registration-path"
          cx="50"
          cy="50"
          fill="none"
          r="40"
          strokeMiterlimit="20"
          strokeWidth="2"
        ></circle>
      </svg>
    </div>
  );
};

export default Loading;
