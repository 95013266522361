import React from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabWrapper } from "../ui/Tabs";

export const TabNavigation = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.TabNavigation" });
  return (
    <TabWrapper>
      <Tab data-test-id="nav-dashboard" to="/dashboard">
        {t("dashboard")}
      </Tab>
      <Tab data-test-id="nav-settings" to="/settings">
        {t("settings")}
      </Tab>
      <Tab data-test-id="nav-assets" to="/assets">
        {t("assets")}
      </Tab>
      <Tab data-test-id="nav-history" to="/history">
        {t("history")}
      </Tab>
    </TabWrapper>
  );
};

//      <Tab data-test-id="nav-support" to="/support">
//        {t("support")}
//      </Tab>
