import React from "react";
import { useTranslation } from "react-i18next";
import { ImageSuggestionKindT, SuggestionKindT } from "../graphql/generated/graphql";
import { ButtonTab } from "../ui/Tabs";

export const AssetNavigation = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.AssetsNavigation" });
  return (
    <div style={{ marginBottom: "0px", marginTop: "20px" }}>
      <ButtonTab data-test-id="assets-table" to="/assets" exactMatch>
        {t("table")}
      </ButtonTab>
      <ButtonTab
        data-test-id="assets-SQUARE_MARKETING_IMAGE"
        to={`/assets/${ImageSuggestionKindT.SquareMarketingImageT}`}
      >
        {t("square_marketing_image")}
      </ButtonTab>
      <ButtonTab data-test-id="assets-MARKETING_IMAGE" to={`/assets/${ImageSuggestionKindT.MarketingImageT}`}>
        {t("marketing_image")}
      </ButtonTab>
      <ButtonTab data-test-id="assets-SHORT_HEADLINE" to={`/assets/${SuggestionKindT.ShortHeadlineT}`}>
        {t("short_headline")}
      </ButtonTab>
      <ButtonTab data-test-id="assets-LONG_HEADLINE" to={`/assets/${SuggestionKindT.LongHeadlineT}`}>
        {t("long_headline")}
      </ButtonTab>
      <ButtonTab data-test-id="assets-SHORT_DESCRIPTION" to={`/assets/${SuggestionKindT.ShortDescriptionT}`}>
        {t("short_description")}
      </ButtonTab>
      <ButtonTab data-test-id="assets-LONG_DESCRIPTION" to={`/assets/${SuggestionKindT.LongDescriptionT}`}>
        {t("long_description")}
      </ButtonTab>
    </div>
  );
};
