import React, { useState } from "react";
import { shuffle } from "lodash";
import { useTranslation } from "react-i18next";
import {
  ActiveImagesAssetLinesQueryT,
  ActiveTextsAssetLinesQueryT,
  AssetLineKindT,
  ImageSuggestionKindT,
  OrganizationDataQueryT,
  UploadedImageFragmentT,
} from "../../graphql/generated/graphql";
import "./style.css";
import { formatMoney } from "../../i18n/formatNumbers";
import { ImageSuggestionPreview, UploadedImageSuggestionPreview } from "../ImageAssetLines/ImageSuggestion";
import context_background from "./context_background.png";
import context_background_mobile from "./context_background_mobile.png";
import external_icon from "./external_icon.png";
import icon_context from "./icon_context.png";
import icon_search from "./icon_search.png";
import icon_shopping from "./icon_shopping.png";
import icon_youtube from "./icon_youtube.png";
import { Pagination } from "./Pagination";
import search_additional_content from "./search_additional_content.png";
import search_background from "./search_background.png";
import shopping_background from "./shopping_background.png";
import youtube_background from "./youtube_background.png";

type PossibleStringT = string | null;
type ImageSuggestionT = {
  height: number;
  svgLink: string;
  width: number;
};
type PreviewProps = {
  businessName?: PossibleStringT;
  description?: PossibleStringT;
  descriptions?: PossibleStringT[] | null;
  domain?: PossibleStringT;
  headline?: PossibleStringT;
  headlines?: PossibleStringT[] | null;
  imageSuggestion?: ImageSuggestionT | null;
  imageUrl?: PossibleStringT;
  logo?: PossibleStringT;
  price?: PossibleStringT;
  uploadedImage?: UploadedImageFragmentT | null;
};
export const ContextPreview = ({
  description,
  headline,
  imageSuggestion,
  imageUrl,
  logo,
  uploadedImage,
}: PreviewProps) => {
  return (
    <div
      style={{
        fontFamily: "Roboto",
        background: "white",
        width: "320px",
        height: "268px",
        border: "1px solid #e4e4e4",
        position: "relative",
      }}
    >
      <span style={{ background: "white", width: "17px", height: "17px", top: 0, right: 0, position: "absolute" }}>
        <span
          style={{
            fontSize: "8px",
            lineHeight: "9px",
            fontWeight: 800,
            position: "relative",
            top: "3px",
            left: "2px",
            display: "block",
            width: "12px",
            height: "12px",
            textAlign: "center",
            border: "2px solid #4eabc9",
            color: "#4eabc9",
            borderRadius: "10px",
          }}
        >
          i
        </span>
      </span>
      <div style={{ width: "318px", height: "167px" }}>
        {imageUrl && (
          <div
            key={imageUrl}
            style={{
              background: `url("${imageUrl}")`,
              backgroundPosition: "center center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "100%",
            }}
          />
        )}
        {imageSuggestion && <ImageSuggestionPreview {...imageSuggestion} maxWidth={318} />}
        {uploadedImage && <UploadedImageSuggestionPreview {...uploadedImage} maxWidth={318} />}
      </div>
      <div
        style={{
          fontWeight: 800,
          position: "absolute",
          top: "172px",
          left: "12px",
          right: "12px",
          fontSize: "20px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {headline}
      </div>
      <div
        style={{
          position: "absolute",
          top: "221px",
          left: "73px",
          right: "60px",
          fontSize: "15px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {description}
      </div>
      <div
        style={{
          position: "absolute",
          top: "210px",
          left: "14px",
          width: "45px",
          height: "45px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {logo && <img alt="Logo" src={logo} />}
      </div>
      <div
        style={{
          position: "absolute",
          background: "black",
          top: "212px",
          right: "13px",
          width: "40px",
          height: "40px",
          borderRadius: "45px",
        }}
      >
        <div
          style={{
            left: "11px",
            top: "14px",
            borderStyle: "solid",
            borderWidth: "0.25em 0.25em 0 0",
            display: "inline-block",
            height: "12px",
            color: "white",
            width: "12px",
            transform: "rotate(45deg)",
            position: "relative",
            verticalAlign: "top",
          }}
        />
      </div>
      {/* <img src={placeholder_context} width="320" style={{position: 'absolute', top: 0, left: 0, opacity: 0.1}}/> */}
    </div>
  );
};

export const ContextWithBackground = (props: PreviewProps) => {
  return (
    <div>
      <div className="show-on-mobile">
        <div style={{ position: "relative", width: "355px", height: "649px" }}>
          <img src={context_background_mobile} style={{ position: "absolute", left: 0, top: 0 }} width="355" />
          {/* <img src={placeholder_context_background} width="856"style={{position: 'absolute', left: 0, top: 0, opacity: 0.5}}/> */}
          <div style={{ position: "absolute", top: "210px", left: "17px" }}>
            <ContextPreview {...props} />
          </div>
        </div>
      </div>
      <div className="show-on-destop">
        <div style={{ position: "relative", width: "856px", height: "618px" }}>
          <img src={context_background} style={{ position: "absolute", left: 0, top: 0 }} width="856" />
          {/* <img src={placeholder_context_background} width="856"style={{position: 'absolute', left: 0, top: 0, opacity: 0.5}}/> */}
          <div style={{ position: "absolute", top: "288px", right: "14px" }}>
            <ContextPreview {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const YoutubePreview = ({
  businessName,
  description,
  headline,
  imageSuggestion,
  imageUrl,
  uploadedImage,
}: PreviewProps) => {
  return (
    <div
      style={{
        fontFamily: "Roboto",
        width: "300px",
        height: "143px",
        position: "relative",
      }}
    >
      <div style={{ width: "143px", height: "143px" }}>
        {imageUrl && (
          <div
            key={imageUrl}
            style={{
              background: `url("${imageUrl}")`,
              backgroundPosition: "center center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "100%",
            }}
          />
        )}
        {imageSuggestion && <ImageSuggestionPreview {...imageSuggestion} maxWidth={143} />}
        {uploadedImage && <UploadedImageSuggestionPreview {...uploadedImage} maxWidth={143} />}
      </div>
      <div
        style={{
          position: "absolute",
          top: "0px",
          left: "150px",
          right: "19px",
          fontSize: "14px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {headline}
      </div>
      <div
        style={{
          position: "absolute",
          top: "23px",
          left: "150px",
          right: "16px",
          fontSize: "12px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {description}
      </div>
      <div
        style={{
          position: "absolute",
          top: "45px",
          left: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span style={{ background: "#e1c256", padding: "1px", borderRadius: "3px", color: "white", fontSize: "10px" }}>
          Reklama
        </span>
        <span style={{ fontSize: "10px", marginLeft: "10px", color: "#606060" }}>{businessName}</span>
      </div>
      <div
        style={{
          position: "absolute",
          background: "#f3f8fe",
          top: "112px",
          right: "0px",
          width: "147px",
          height: "30px",
          color: "#3b86f7",
          padding: "7px 12px",
          fontWeight: 700,
          fontSize: "14px",
          overflow: "hidden",
        }}
      >
        Nakoupit
        <img
          alt="External"
          src={external_icon}
          style={{ position: "absolute", top: "9px", right: "14px" }}
          width="17"
        />
      </div>
    </div>
  );
};

export const YoutubeWithBackground = (props: PreviewProps) => {
  return (
    <div className="AdPreview-crop-on-mobile">
      <div className="AdPreview-crop-on-mobile-content">
        <div style={{ position: "relative", width: "377px", height: "618px", overflow: "hidden" }}>
          <img src={youtube_background} style={{ position: "absolute", left: 0, top: 0 }} width="377" />
          <div style={{ position: "absolute", top: "270px", left: "40px" }}>
            <YoutubePreview {...props} />
          </div>
          {/*<img src={placeholder_youtube} style={{ position: "absolute", left: 0, top: 0, opacity: 0.5 }} width="377" />*/}
        </div>
      </div>
    </div>
  );
};
export const PlaPreview = ({ domain, headline, imageUrl, price }: PreviewProps) => {
  return (
    <div
      style={{
        fontFamily: "Roboto",
        background: "white",
        width: "180px",
        height: "361px",
        border: "2px solid #e4e4e4",
        borderRadius: "8px",
        position: "relative",
      }}
    >
      <div style={{ width: "176px", height: "180px" }}>
        <div
          key={imageUrl}
          style={{
            background: `url("${imageUrl}")`,
            backgroundPosition: "center center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div className="PlaPreview-headline">{headline}</div>
      <div
        style={{
          fontWeight: 800,
          position: "absolute",
          top: "269px",
          left: "12px",
          right: "12px",
          fontSize: "19px",
          lineHeight: "24px",
        }}
      >
        {price}
      </div>
      <div
        style={{
          color: "#3871e0",
          position: "absolute",
          top: "294px",
          letterSpacing: "-1.1px",
          left: "12px",
          right: "12px",
          fontSize: "19px",
          lineHeight: "24px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {domain?.replace(/https?:\/\//, "")}
      </div>
      {/* <img src={placeholder_pla} width="180" style={{position: 'absolute', top: 0, left: 0, opacity: 0.5}}/> */}
    </div>
  );
};

export const PlaWithBackground = (props: PreviewProps) => {
  return (
    <div className="AdPreview-crop-on-mobile">
      <div className="AdPreview-crop-on-mobile-content">
        <div style={{ position: "relative", width: "377px", height: "618px", overflow: "hidden" }}>
          <img src={shopping_background} style={{ position: "absolute", left: 0, top: 0 }} width="377" />
          {/*<img src={shopping_placeholder} width="377" style={{position: 'absolute', left: 0, top: 0, opacity: 0.5}}/>*/}
          <div style={{ position: "absolute", top: "195px", left: "26px" }}>
            <div style={{ transform: "scale(0.865)", transformOrigin: "left top" }}>
              <PlaPreview {...props} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const SearchPreview = ({ descriptions, domain, headlines }: PreviewProps) => {
  return (
    <div
      style={{
        fontFamily: "Roboto",
        background: "white",
        width: "578px",
        minHeight: "124px",
        padding: "17px",
        border: "1px solid #e4e4e4",
        position: "relative",
      }}
    >
      {/*<img src={placeholder_search} width="578" style={{position: 'absolute', top: 0, left: 0, opacity: 0.5}}/>*/}
      <div style={{ position: "absolute", left: "17px", top: "18px", fontSize: "15px", color: "#3c4043" }}>
        <span style={{ fontWeight: 700 }}>Reklama</span> · <span style={{ letterSpacing: "-1px" }}>{domain}</span>
      </div>
      <div
        style={{
          marginTop: "35px",
          fontSize: "21px",
          lineHeight: "23px",
          color: "#1a0dab",
          letterSpacing: "0.3px",
        }}
      >
        {headlines?.slice(0, 3).join(" | ")}
      </div>
      <div
        style={{
          marginTop: "11px",
          fontSize: "13px",
          color: "#3c4043",
          letterSpacing: "0.5px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {descriptions
          ?.slice(0, 2)
          .map((desc) => (desc?.endsWith(".") ? desc : `${desc}.`))
          .join(" ")}
      </div>
    </div>
  );
};

export const SearchPreviewMobile = ({ descriptions, domain, headlines }: PreviewProps) => {
  return (
    <div
      style={{
        fontFamily: "Roboto",
        background: "white",
        width: "318px",
        minHeight: "100px",
        padding: "10px",
        border: "1.5px solid #e4e4e4",
        borderRadius: "9px",
        position: "relative",
      }}
    >
      {/*<img src={placeholder_search} width="578" style={{position: 'absolute', top: 0, left: 0, opacity: 0.5}}/>*/}
      <div
        style={{ position: "absolute", left: "10px", right: "10px", top: "12px", fontSize: "12px", color: "#3c4043" }}
      >
        <span style={{ fontWeight: 700 }}>Reklama</span> · <span style={{ letterSpacing: "-1px" }}>{domain}</span>
      </div>
      <div
        style={{
          marginTop: "36px",
          fontSize: "16px",
          lineHeight: "18px",
          color: "#1a0dab",
          letterSpacing: "0.7px",
        }}
      >
        {headlines?.slice(0, 3).join(" | ")}
      </div>
      <div
        style={{
          marginTop: "16px",
          fontSize: "13px",
          color: "#3c4043",
          letterSpacing: "0.1px",
        }}
      >
        {descriptions
          ?.slice(0, 2)
          .map((desc) => (desc?.endsWith(".") ? desc : `${desc}.`))
          .join(" ")}
      </div>
    </div>
  );
};
export const SearchWithBackground = (props: PreviewProps) => {
  return (
    <div className="AdPreview-crop-on-mobile">
      <div className="AdPreview-crop-on-mobile-content">
        <div style={{ position: "relative", width: "377px", height: "618px", overflow: "hidden" }}>
          <img src={search_background} style={{ position: "absolute", left: 0, top: 0 }} width="377" />
          {/*<img src={placeholder_search_background} width="377"style={{position: 'absolute', left: 0, top: 0, opacity: 0.5}}/>*/}
          <div style={{ position: "absolute", top: "195px", left: "30px", bottom: "0px", right: "0px" }}>
            <SearchPreviewMobile {...props} />
            <img src={search_additional_content} style={{ marginTop: "10px" }} width="318" />
          </div>
        </div>
      </div>
    </div>
  );
};

type AdPreviewPropsT = {
  activeImagesAssetLines: NonNullable<
    NonNullable<ActiveImagesAssetLinesQueryT["organization"]>["activeImagesAssetLines"]
  >;
  activeTextAssetLines: NonNullable<NonNullable<ActiveTextsAssetLinesQueryT["organization"]>["activeTextsAssetLines"]>;
  organization: OrganizationDataQueryT["organization"];
};

const getDomain = (url?: PossibleStringT) => (url || "example.com").replace(/https?:\/\//, "").split("/")[0];

type SearchVariantsPropsT = {
  activeTextAssetLines: NonNullable<NonNullable<ActiveTextsAssetLinesQueryT["organization"]>["activeTextsAssetLines"]>;
  organization: OrganizationDataQueryT["organization"];
};

const SearchVariants = ({ activeTextAssetLines, organization }: SearchVariantsPropsT) => {
  const headlines = activeTextAssetLines
    .filter((line) => line.kind === AssetLineKindT.ShortHeadlineT)
    .map(({ content }) => content);
  const descriptions = activeTextAssetLines
    .filter((line) => line.kind === AssetLineKindT.ShortDescriptionT || line.kind === AssetLineKindT.LongDescriptionT)
    .map(({ content }) => content);
  const variations = Array.from({ length: headlines.length - 2 + descriptions.length - 2 }, (value, index) => ({
    id: index,
    headlines: shuffle(headlines),
    descriptions: shuffle(descriptions),
  }));
  return (
    <div>
      <Pagination count={variations.length}>
        {(index) => (
          <SearchWithBackground key={index} domain={getDomain(organization?.shoptetUrl)} {...variations[index]} />
        )}
      </Pagination>
    </div>
  );
};

const ContextVariants = ({ activeImagesAssetLines, activeTextAssetLines, organization }: AdPreviewPropsT) => {
  const imageSuggestions = activeImagesAssetLines.filter(
    (line) => (line.kind as string) === (ImageSuggestionKindT.MarketingImageT as string)
  );
  const headlines = activeTextAssetLines
    .filter((line) => line.kind === AssetLineKindT.ShortHeadlineT || line.kind === AssetLineKindT.LongHeadlineT)
    .map(({ content }) => content);
  const descriptions = activeTextAssetLines
    .filter((line) => line.kind === AssetLineKindT.ShortDescriptionT || line.kind === AssetLineKindT.LongDescriptionT)
    .map(({ content }) => content);

  const variations = Array.from(
    { length: imageSuggestions.length + headlines.length + descriptions.length },
    (value, index) => {
      const { imageSuggestion, uploadedImage } = imageSuggestions[index % imageSuggestions.length];
      return {
        id: index,
        imageSuggestion,
        uploadedImage,
        description: shuffle(descriptions)[0],
        headline: shuffle(headlines)[0],
      };
    }
  );
  return (
    <div>
      <Pagination count={variations.length}>
        {(index) => (
          <ContextWithBackground key={index} logo={organization?.organizationDesign?.logo} {...variations[index]} />
        )}
      </Pagination>
    </div>
  );
};

const YoutubeVariants = ({ activeImagesAssetLines, activeTextAssetLines, organization }: AdPreviewPropsT) => {
  const imageSuggestions = activeImagesAssetLines.filter(
    (line) => (line.kind as string) === (ImageSuggestionKindT.SquareMarketingImageT as string)
  );
  const headlines = activeTextAssetLines
    .filter((line) => line.kind === AssetLineKindT.ShortHeadlineT || line.kind === AssetLineKindT.LongHeadlineT)
    .map(({ content }) => content);
  const descriptions = activeTextAssetLines
    .filter((line) => line.kind === AssetLineKindT.ShortDescriptionT || line.kind === AssetLineKindT.LongDescriptionT)
    .map(({ content }) => content);

  const variations = Array.from(
    { length: imageSuggestions.length + headlines.length + descriptions.length },
    (value, index) => {
      const { imageSuggestion, uploadedImage } = imageSuggestions[index % imageSuggestions.length];
      return {
        id: index,
        imageSuggestion,
        uploadedImage,
        description: shuffle(descriptions)[0],
        headline: shuffle(headlines)[0],
      };
    }
  );
  return (
    <div>
      <Pagination count={variations.length}>
        {(index) => (
          <YoutubeWithBackground
            businessName={organization?.shoptetEshopName}
            logo={organization?.organizationDesign?.logo}
            {...variations[index]}
            key={index}
          />
        )}
      </Pagination>
    </div>
  );
};

type ShoppingVariantsPropsT = {
  organization: OrganizationDataQueryT["organization"];
};
const ShoppingVariants = ({ organization }: ShoppingVariantsPropsT) => {
  const variations = organization?.selectedProducts || [];
  return (
    <div>
      <Pagination count={variations.length}>
        {(index) => {
          const variant = variations[index];
          return (
            <PlaWithBackground
              key={index}
              domain={organization?.shoptetUrl}
              headline={variant.name}
              {...variant}
              price={variant.price ? formatMoney(variant.price) : ""}
            />
          );
        }}
      </Pagination>
    </div>
  );
};
enum PagesT {
  search,
  shopping,
  context,
  youtube,
}
export const AdPreview = ({ activeImagesAssetLines, activeTextAssetLines, organization }: AdPreviewPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.AdPreview" });
  const [page, setPage] = useState(PagesT.search);
  return (
    <div>
      <ul className="AdPreview-Menu">
        <li className={page === PagesT.search ? "active" : ""} onClick={() => setPage(PagesT.search)}>
          <img alt="{t('search')}" src={icon_search} />
          {t("search")}
        </li>
        <li className={page === PagesT.shopping ? "active" : ""} onClick={() => setPage(PagesT.shopping)}>
          <img alt="{t('shopping')}" src={icon_shopping} />
          {t("shopping")}
        </li>
        <li className={page === PagesT.youtube ? "active" : ""} onClick={() => setPage(PagesT.youtube)}>
          <img alt="{t('youtube')}" src={icon_youtube} />
          {t("youtube")}
        </li>
        <li className={page === PagesT.context ? "active" : ""} onClick={() => setPage(PagesT.context)}>
          <img alt="{t('context')}" src={icon_context} />
          {t("context")}
        </li>
      </ul>
      <div className="AdPreview-Container">
        {page === PagesT.search && (
          <SearchVariants activeTextAssetLines={activeTextAssetLines} organization={organization} />
        )}

        {page === PagesT.context && (
          <ContextVariants
            activeImagesAssetLines={activeImagesAssetLines}
            activeTextAssetLines={activeTextAssetLines}
            organization={organization}
          />
        )}

        {page === PagesT.youtube && (
          <YoutubeVariants
            activeImagesAssetLines={activeImagesAssetLines}
            activeTextAssetLines={activeTextAssetLines}
            organization={organization}
          />
        )}

        {page === PagesT.shopping && <ShoppingVariants organization={organization} />}
      </div>
    </div>
  );
};
