import React from "react";
import { useTranslation } from "react-i18next";
import { AssetNavigation } from "./AssetNavigation";
import { CampaignState } from "./CampaignState";
import { TabNavigation } from "./TabNavigation";

export const AssetPageHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Assets Page" });
  return (
    <div>
      <h1>{t("Headline")}</h1>
      <CampaignState />
      <TabNavigation />
      <AssetNavigation />
    </div>
  );
};
