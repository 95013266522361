import { useEffect } from "react";
import { WizardStepT, useSetWizardStepMutation } from "../../graphql/generated/graphql";

type UseSetWizardStepEffectT = {
  wizardStep: WizardStepT;
};
export const useSetWizardStepEffect = ({ wizardStep }: UseSetWizardStepEffectT) => {
  const [setWizardStep] = useSetWizardStepMutation({ variables: { wizardStep } });
  useEffect(() => {
    setWizardStep();
  }, [setWizardStep]);
  return [];
};
