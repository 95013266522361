import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "../elements/Icons";

export const NoProducts = () => {
  const { t } = useTranslation("translation", { keyPrefix: "No Products Page" });

  return (
    <div>
      <h1>{t("Headline")}</h1>
      <div style={{ width: "390px", marginInline: "auto" }}>
        <div style={{ textAlign: "center" }}>
          <Icon className="icon-size-96" kind="delete-item" style={{ color: "#DB1B4E" }} />
        </div>
        <h3 style={{ textAlign: "center", color: "#DB1B4E" }}>{t("Sub headline")}</h3>
        <div style={{ textAlign: "center", fontSize: "12px", lineHeight: "16px", margin: "20px 0px" }}>
          {t("Sub text")}
        </div>
      </div>
    </div>
  );
};
