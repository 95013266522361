import React, { ReactNode, useContext, useState } from "react";
import { API_PATH } from "../constants/common";

export type OrganizationTypeT = { id: string; name: string };

type StateT = {
  apiToken?: string;
  apiUri: string;
  currentUrl: string;
  host: string;
};

type AppSettiongsContextT =
  | null
  | (StateT & {
      setAppSettingsState: React.Dispatch<React.SetStateAction<StateT>>;
    });

const rootElement = document.getElementById("shoptet-root") as HTMLElement;

const host = rootElement.dataset.host || "/";

const defaultState = {
  host,
  apiToken: rootElement.dataset.apiToken as string,
  apiUri: `${host}${API_PATH}`,
  currentUrl: rootElement.dataset.appUrl as string,
};

const AppSettingsContext = React.createContext<AppSettiongsContextT>(null);

export const AppSettingsProvider = (props: { children: ReactNode }) => {
  const [state, setState] = useState<StateT>(defaultState);

  return (
    <AppSettingsContext.Provider value={{ ...state, setAppSettingsState: setState }}>
      {props.children}
    </AppSettingsContext.Provider>
  );
};

export const useAppSettings = () => {
  const context = useContext(AppSettingsContext);
  if (!context) {
    throw new Error("Component is outside appSettings provider");
  }
  return context;
};
