import React from "react";
import { useTranslation } from "react-i18next";
import { AuditLogs } from "../elements/AuditLogs";
import { CampaignState } from "../elements/CampaignState";
import { HistoryNavigation } from "../elements/HistoryNavigation";
import { TabNavigation } from "../elements/TabNavigation";

export const HistoryAuditLogs = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Audit Log Page" });
  return (
    <div>
      <h1>{t("Headline")}</h1>
      <CampaignState />
      <TabNavigation />
      <HistoryNavigation />
      <h2 style={{ marginBottom: "32px" }}>{t("Audit log Headline")}</h2>
      <AuditLogs />
    </div>
  );
};
