import React from "react";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatIssueDescription } from "../elements/GmcIssues";
import { InfoBox, WarningBox } from "../elements/InfoBox";
import { Products } from "../elements/Products";

export const ProductsPage = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Dashboard Page" });
  const navigate = useNavigate();
  const { t: tGmc } = useTranslation("translation", { keyPrefix: "Components.GMCIssues" });
  const [searchParams] = useSearchParams();
  const gmcWarning = searchParams.get("gmc_warning");
  const gmcError = searchParams.get("gmc_error");
  const gmcIssueId = searchParams.get("gmc_issue_id");
  return (
    <div>
      <h1>{t("Products with recommendation")}</h1>
      <Button
        data-test-id="back-to-dashboard"
        variant="action"
        onClick={() => {
          navigate("/dashboard");
        }}
      >
        {t("back")}
      </Button>

      {gmcWarning && <InfoBox style={{ margin: "15px 0px" }}>{formatIssueDescription(tGmc, gmcWarning)}</InfoBox>}
      {gmcError && <WarningBox>{formatIssueDescription(tGmc, gmcError)}</WarningBox>}
      <Products gmcError={gmcError} gmcIssueId={gmcIssueId} gmcWarning={gmcWarning} />
    </div>
  );
};
