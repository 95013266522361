import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckIcon,
  DoneStepIcon,
  ImageSelectionIcon,
  InputDataIcon,
  SettingsIcon,
  StepperIconPropsT,
  TextSelectionIcon,
} from "./Icons";

export enum StepsT {
  STEP_SETUP,
  STEP_SUGGESTION,
  STEP_IMAGE,
  STEP_TEXT,
  STEP_CHECK,
}

const stepOrder = [StepsT.STEP_SETUP, StepsT.STEP_SUGGESTION, StepsT.STEP_IMAGE, StepsT.STEP_TEXT, StepsT.STEP_CHECK];

type StepPropsT = {
  Icon: (props: StepperIconPropsT) => JSX.Element;
  children: ReactNode;
  currentStep: StepsT;
  step: StepsT;
};

const Step = ({ Icon, children, currentStep, step }: StepPropsT) => {
  const donePrevious = stepOrder.indexOf(currentStep) > stepOrder.indexOf(step);
  const done = stepOrder.indexOf(currentStep) >= stepOrder.indexOf(step);
  const doneNext = stepOrder.indexOf(currentStep) >= stepOrder.indexOf(step) + 1;
  const isFirst = stepOrder.indexOf(step) === 0;
  const isLast = stepOrder.indexOf(step) === stepOrder.length - 1;
  const textAlign = (isFirst && "left") || (isLast && "right") || "center";

  const firstStyle = isFirst ? {} : {};
  const centerStyle = isFirst || isLast ? {} : { transform: "translateX(-50%)", left: "50%" };
  const lastStyle = isLast ? { right: 0 } : {};

  return (
    <>
      {!isFirst && (
        <div
          style={{
            textAlign,
            height: "60px",
            width: `${100 / (stepOrder.length * 2 - 1)}%`,
            display: "inline-block",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              height: "1px",
              background: done ? "#14B1EF" : "#E9E9E9",
              width: "100%",
              top: "29px",
            }}
          />
        </div>
      )}
      <div
        style={{
          textAlign,
          width: `${100 / (stepOrder.length * 2 - 1)}%`,
          display: "inline-block",
          position: "relative",
        }}
      >
        {!isFirst && (
          <div
            style={{
              position: "absolute",
              height: "1px",
              background: done ? "#14B1EF" : "#E9E9E9",
              width: isLast ? "100%" : "50%",
              top: "29px",
            }}
          />
        )}
        {!isLast && (
          <div
            style={{
              position: "absolute",
              height: "1px",
              background: doneNext ? "#14B1EF" : "#E9E9E9",
              width: isFirst ? "100%" : "50%",
              top: "29px",
              right: 0,
            }}
          />
        )}
        <div
          style={{
            marginLeft: isFirst ? "0px" : "auto",
            marginRight: isLast ? "0px" : "auto",
            width: "60px",
            height: "60px",
            background: (done && donePrevious && "#9DCA57") || (done && "#14B1EF") || "#E9E9E9",
            border: "10px solid white",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "25px",
              width: "25px",
            }}
          >
            {donePrevious ? <DoneStepIcon /> : <Icon color={done ? "#FFFFFF" : "#BBBBBB"} />}
          </div>
        </div>
        <div
          style={{
            width: "400px",
            maxWidth: "150%",
            position: "absolute",
            ...firstStyle,
            ...centerStyle,
            ...lastStyle,
            fontWeight: done && !donePrevious ? 700 : 400,
            color: (done && donePrevious && "#BBBBBB") || (done && "#14B1EF") || "#37373D",
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

type ProgressStepperPropsT = {
  currentStep: StepsT;
};
export const ProgressStepper = ({ currentStep }: ProgressStepperPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.WizardStepper" });
  return (
    <div className="ProgressStepper-container" style={{ minHeight: "50px", marginBottom: "30px" }}>
      <Step currentStep={currentStep} Icon={SettingsIcon} step={StepsT.STEP_SETUP}>
        {t("Settings")}
      </Step>
      <Step currentStep={currentStep} Icon={InputDataIcon} step={StepsT.STEP_SUGGESTION}>
        {t("Generate segguestion")}
      </Step>
      <Step currentStep={currentStep} Icon={ImageSelectionIcon} step={StepsT.STEP_IMAGE}>
        {t("Banner slection")}
      </Step>
      <Step currentStep={currentStep} Icon={TextSelectionIcon} step={StepsT.STEP_TEXT}>
        {t("Text selection")}
      </Step>
      <Step currentStep={currentStep} Icon={CheckIcon} step={StepsT.STEP_CHECK}>
        {t("Check")}
      </Step>
    </div>
  );
};
