import React, { ReactNode, useState } from "react";
import "./Pagination.css";
import { Button } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { BackIcon, NextIcon } from "../Icons";

type PaginationButtonPropsT = {
  disabled?: boolean;
  onClick: () => void;
};

export const PaginatePrevious = ({ disabled, onClick }: PaginationButtonPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.Pagination" });
  if (disabled) {
    return null;
  }
  return (
    <Button variant="muted" onClick={onClick}>
      <span style={{ display: "flex", alignItems: "center" }}>
        <BackIcon />
        <span>
          &nbsp;
          {t("previous")}
        </span>
      </span>
    </Button>
  );
};

export const PaginateNext = ({ disabled, onClick }: PaginationButtonPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.Pagination" });
  if (disabled) {
    return null;
  }
  return (
    <Button style={{ marginLeft: "10px" }} variant="muted" onClick={onClick}>
      <span style={{ display: "flex", alignItems: "center" }}>
        <span>
          {t("next")}
          &nbsp;
        </span>
        <NextIcon />
      </span>
    </Button>
  );
};

export const PaginateArrowPrevious = ({ disabled, onClick }: PaginationButtonPropsT) => (
  <div className={`Pagination-arrow Pagination-previous ${disabled ? "disabled" : ""}`} onClick={onClick} />
);

export const PaginateArrowNext = ({ disabled, onClick }: PaginationButtonPropsT) => (
  <div className={`Pagination-arrow Pagination-next ${disabled ? "disabled" : ""}`} onClick={onClick} />
);

export const PaginationContainer = ({ children }: { children: ReactNode }) => (
  <div className="Pagination-container">{children}</div>
);

export const PaginationContainerCenter = ({ children }: { children: ReactNode }) => (
  <div className="Pagination-container-center">{children}</div>
);

type PaginationPropsT = {
  children: (index: number) => ReactNode;
  count: number;
};

export const Pagination = ({ children, count }: PaginationPropsT) => {
  const [currentPage, setPage] = useState(0);
  const pages = Array.from({ length: count }, (value, index) => index);

  return (
    <div>
      {children(currentPage)}
      <PaginationContainerCenter>
        <PaginateArrowPrevious
          disabled={currentPage === 0}
          onClick={() => setPage((state) => Math.max(state - 1, 0))}
        />
        {pages.map((page) => (
          <div
            key={page}
            className={`Pagination-page ${currentPage === page ? "active" : ""}`}
            onClick={() => setPage(page)}
          />
        ))}
        <PaginateArrowNext
          disabled={currentPage === count - 1}
          onClick={() => setPage((state) => Math.min(state + 1, count - 1))}
        />
      </PaginationContainerCenter>
    </div>
  );
};
