import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion } from "../elements/Accordion";
import { AudienceSignals } from "../elements/AdvancedSetup/AudienceSignals";
import { BiddingScheme } from "../elements/AdvancedSetup/BiddingScheme";
import { Deactivate } from "../elements/AdvancedSetup/Deactivate";
import { ExcludeProducts } from "../elements/AdvancedSetup/ExcludeProducts";
import { ExtendedConversions } from "../elements/AdvancedSetup/ExtendedConversions";
import { CampaignState } from "../elements/CampaignState";
import { CategorySelectionWrapper } from "../elements/CategoriesSelection";
import { ReturnCredits } from "../elements/Credits/ReturnCredits";
import { WarningBox } from "../elements/InfoBox";
import { UpdateMonthlyBudget } from "../elements/MonthlyBudget/update";
import { SmallLoader } from "../elements/SmallLoader";
import { SuggestionInputForm } from "../elements/SuggestionInputForm";
import { TabNavigation } from "../elements/TabNavigation";
import { useLastSuggestionRequestQuery } from "../graphql/generated/graphql";

export const Settings = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Settings Page" });
  const { t: tSuggestions } = useTranslation("translation", { keyPrefix: "Wizard Suggestion Generator Page" });
  const { data: lastSuggestionRequestData, loading: lastSuggestionRequestLoading } = useLastSuggestionRequestQuery({
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <h1>{t("Headline")}</h1>
      <CampaignState />
      <TabNavigation />
      <h2>{t("Budget Headline")}</h2>
      <UpdateMonthlyBudget />

      <br />
      <Accordion header={t("Input data Headline")}>
        {lastSuggestionRequestLoading ? (
          <SmallLoader />
        ) : (
          <>
            <WarningBox>{tSuggestions("WarningBox")}</WarningBox>
            <SuggestionInputForm
              category={lastSuggestionRequestData?.organization?.category}
              continueToNextStepText={tSuggestions("Update")}
              defaultUserInput={
                lastSuggestionRequestData?.organization?.lastSuggestionRequestsShortHeadline?.[0]?.userInput
              }
              hideCategories
            />
          </>
        )}
      </Accordion>
      <br />
      <br />

      <Accordion header={tSuggestions("Selected categories headline")}>
        <WarningBox>{tSuggestions("Advanced options info box")}</WarningBox>
        <p>{tSuggestions("Selected categories description")}</p>
        <CategorySelectionWrapper />
      </Accordion>
      <br />
      <br />

      <Accordion header={t("Advanced Settings Headline")}>
        <WarningBox>{t("Advanced Settings WarningBox")}</WarningBox>
        <h3>{t("BiddingScheme")}</h3>
        <p>{t("BiddingScheme Text")}</p>
        <BiddingScheme />
        <h3>{t("AudienceSignals")}</h3>
        <p>{t("AudienceSignals Text")}</p>
        <AudienceSignals />
        <h3>{t("ExtendedConversions")}</h3>
        <p>{t("ExtendedConversions Text")}</p>
        <ExtendedConversions />
        <h3>{t("ExcludeProducts")}</h3>
        <ExcludeProducts />
      </Accordion>
      <br />
      <br />

      <Accordion header={t("Return Credits Headline")}>
        <ReturnCredits />
      </Accordion>
      <br />
      <br />

      <Deactivate />
    </div>
  );
};
