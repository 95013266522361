/* eslint-disable @typescript-eslint/no-explicit-any */
import { Notifier } from "@airbrake/browser";

export const airbrake = new Notifier({
  projectId: 435714,
  projectKey: "9146bc574c70e704714f54e7a1f19cd3",
  environment: "production",
});

export const airbrakeNotify = (event: any) => {
  if (process.env.NODE_ENV === "production") {
    airbrake.notify(event);
  }
};
