import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonTab } from "../ui/Tabs";

export const HistoryNavigation = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.HistoryNavigation" });
  return (
    <div style={{ marginBottom: "32px", marginTop: "20px" }}>
      <ButtonTab data-test-id="history-credits" to="/history" exactMatch>
        {t("credits")}
      </ButtonTab>
      <ButtonTab data-test-id="history-audit-logs" to={`/history/audit-logs`}>
        {t("audit logs")}
      </ButtonTab>
    </div>
  );
};
