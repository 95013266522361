import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type RemainingIndicatorPropsT = {
  children?: ReactNode;
  defaultSeconds: number;
};

const style = {
  color: "#BBBBBB",
  marginTop: "10px",
  textAlign: "center",
} as React.CSSProperties;

export const RemainingIndicator = ({ children, defaultSeconds }: RemainingIndicatorPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.RemainingIndicator" });
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  });

  if (seconds < defaultSeconds) {
    return (
      <div style={style}>
        {children} {t("Remaning {{count}} seconds to finish", { count: defaultSeconds - seconds })}
      </div>
    );
  }
  if (seconds < defaultSeconds + 10) {
    return (
      <div style={style}>
        {children} {t("Just a little longer")}
      </div>
    );
  }

  if (seconds < defaultSeconds + 30) {
    return (
      <div style={style}>
        {children} {t("We will surely finish it")}
      </div>
    );
  }

  return (
    <div style={style}>
      {children} {t("Not so good contact support")}
    </div>
  );
};
