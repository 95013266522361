import React, { ReactNode, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "./Icons";

type AccordionPropsT = {
  children: ReactNode;
  defaultOpened?: boolean;
  header?: string;
  headerContent?: ReactNode;
  showAsDiv?: boolean;
};
export const Accordion = ({ children, defaultOpened, header, headerContent, showAsDiv }: AccordionPropsT) => {
  const [isOpened, setOpened] = useState(!!defaultOpened);

  if (showAsDiv) {
    return (
      <div style={{ width: "100%", padding: "20px" }}>
        {header && (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h2 style={{ margin: 0 }}>{header}</h2>
            <div>{headerContent}</div>
          </div>
        )}
        <div>
          <div>{children}</div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ background: "#F9F9F9", width: "100%", padding: "20px" }}>
      <div
        data-test-id={`Accordion-${header}`}
        style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center" }}
        onClick={() => setOpened((state) => !state)}
      >
        <h2 style={{ margin: 0 }}>{header}</h2>
        <div>
          {headerContent}
          {isOpened ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      </div>
      <div className={`Accordion ${isOpened ? "Accordion--opened" : "Accordion--closed"}`}>
        <div>{children}</div>
      </div>
    </div>
  );
};
