import React, { useCallback, useState } from "react";
import { Button, ColorField } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { useChangeOrganizationDesignMutation, useOrganizationQuery } from "../../graphql/generated/graphql";
import { CheckmarkIcon } from "../Icons";
import { SmallLoader } from "../SmallLoader";

export const PrimaryColor = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.PrimaryColor" });
  const [value, setValue] = useState<string>("000000");
  const [isSaved, setIsSaved] = useState(false);

  const { data, loading } = useOrganizationQuery({
    fetchPolicy: "cache-and-network",
    onCompleted(dataResponse) {
      setValue((dataResponse.organization?.organizationDesign?.finalColor1 || "000000").replace("#", ""));
    },
  });

  const [changeOrganizationDesign, { loading: mutationLoading }] = useChangeOrganizationDesignMutation({
    onCompleted: () => {
      setIsSaved(true);
      setTimeout(() => setIsSaved(false), 2000);
    },
  });

  const onChange = useCallback((newValue: string) => setValue(newValue), [setValue]);

  if (loading || !data?.organization) {
    return <SmallLoader />;
  }

  const invalidColor = !value.match(/^[0-9a-fA-F]{6}$/);

  return (
    <div>
      <ColorField
        disabled={mutationLoading || isSaved}
        label={t("Label")}
        name="primary_color"
        value={value}
        onChange={onChange}
      />
      {invalidColor && <div style={{ color: "#db1b4e" }}>{t("invalid color error")}</div>}
      {(data.organization?.organizationDesign?.finalColor1?.replace("#", "") === value &&
        !mutationLoading &&
        !isSaved && <span />) || (
        <Button
          data-test-id="save-primary-color"
          disabled={mutationLoading || isSaved || invalidColor}
          style={{ margin: "10px 10px 0 0" }}
          variant="action"
          onClick={() => changeOrganizationDesign({ variables: { overrideColor1: `#${value}` } })}
        >
          {(isSaved && (
            <span style={{ display: "flex", alignItems: "center" }}>
              <CheckmarkIcon /> &nbsp; {t("Saved")}
            </span>
          )) ||
            (mutationLoading && t("Saving")) ||
            t("Save")}
        </Button>
      )}
    </div>
  );
};
