import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useActiveImagesAssetLinesQuery,
  useImageSuggestionsQuery,
  useLastImageSuggestionRequestsQuery,
  useNotDeletedImagesAssetLinesQuery,
  useOrganizationValidateImagesQuery,
  useRequestAdditionalImageSuggestionMutation,
} from "../../graphql/generated/graphql";
import { usePrevious } from "../../hooks/usePrevious";

export const useImageAssetLines = (draft = false) => {
  const { data: dataImageValidation } = useOrganizationValidateImagesQuery();
  const { data: dataActiveImageAssetLines, loading: loadingActive } = useActiveImagesAssetLinesQuery({ skip: draft });
  const { data: dataNotDeletedImageAssetLines, loading: loadingNotDeleted } = useNotDeletedImagesAssetLinesQuery({
    skip: !draft,
  });

  const activeImageAssetLines = draft
    ? dataNotDeletedImageAssetLines?.organization?.notDeletedImagesAssetLines
    : dataActiveImageAssetLines?.organization?.activeImagesAssetLines;
  const loadingData = draft ? loadingNotDeleted : loadingActive;
  const validateImages = draft
    ? dataImageValidation?.organization?.validateDraftImages
    : dataImageValidation?.organization?.validateImages;

  return {
    validateImages,
    activeImageAssetLines,
    loadingData,
  };
};

export const useGenerateMoreImageSuggestions = () => {
  const [generatingMoreSuggestions, setGeneratingMoreSuggestions] = useState(true);
  const [polling, setPolling] = useState(true);
  const previousPolling = usePrevious(polling);

  const { data, loading, refetch } = useImageSuggestionsQuery({
    variables: { limit: 200 },
    fetchPolicy: "network-only",
  });

  const {
    data: lastSuggestionRequestData,
    startPolling,
    stopPolling,
  } = useLastImageSuggestionRequestsQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (response) => {
      if (!response.organization?.lastImageSuggestionRequests?.[0]?.inProgress && generatingMoreSuggestions) {
        setPolling(false);
        refetch().then(() => {
          setGeneratingMoreSuggestions(false);
        });
      }
    },
  });

  useEffect(() => {
    if (!previousPolling && polling) {
      setTimeout(() => startPolling(1000), 500);
    }
    if (previousPolling && !polling) {
      stopPolling();
    }
  }, [startPolling, stopPolling, polling, previousPolling]);

  useEffect(() => () => stopPolling(), [stopPolling]);

  const [requestAdditionalImageSuggestions] = useRequestAdditionalImageSuggestionMutation();

  const generateMoreSuggestions = () => {
    if (generatingMoreSuggestions) {
      return;
    }

    if (lastSuggestionRequestData?.organization?.lastImageSuggestionRequests?.[0].id) {
      setGeneratingMoreSuggestions(true);
      requestAdditionalImageSuggestions({
        variables: {},
      }).then(() => {
        setPolling(true);
      });
    } else {
      toast.error("Nemůžeme vygenerovat další návrhy protože jste neprovedli první generování návrhů");
    }
  };

  return {
    generatingMoreSuggestions,
    canFetchMore: !!lastSuggestionRequestData?.organization?.lastImageSuggestionRequests?.[0]?.canFetchMore,
    data,
    loading,
    generateMoreSuggestions,
  };
};
