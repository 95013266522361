import React, { useState } from "react";
import { Button, SystemMessage } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import {
  CurrencyT,
  useChangeMonthlyBudgetMutation,
  useOrganizationMonthlyBudgetQuery,
} from "../../graphql/generated/graphql";
import { formatCurrencyUnit, formatMoney } from "../../i18n/formatNumbers";
import { CheckmarkIcon } from "../Icons";
import NumberFieldWithUnit from "../NumberFieldWithUnit";
import { SmallLoader } from "../SmallLoader";

type MonthlyBudgetPropsT = {
  afterEditButton?: boolean;
  onComplete?: () => void;
};

const TEST_NUMBER = /^\d+[\.,]?\d*$/;
const MINIMAL_MONTHLY_BUDGET = 3000;
const MAXIMAL_MONTHLY_BUDGET = 1000000;

export const MonthlyBudget = ({ afterEditButton, onComplete }: MonthlyBudgetPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.MonthlyBudget" });
  const [isSaved, setIsSaved] = useState(false);

  const [originalState, setOriginalState] = useState({
    currency: CurrencyT.CzkT,
    monthlyBudget: "0",
  });

  const [state, setState] = useState({
    currency: CurrencyT.CzkT,
    monthlyBudget: "0",
  });

  const { loading } = useOrganizationMonthlyBudgetQuery({
    onCompleted: (data) => {
      const newState = {
        monthlyBudget: formatMoney({ amount: data.organization?.monthlyBudget?.amount || 0 }),
        currency: data.organization?.monthlyBudget?.currency || CurrencyT.CzkT,
      };
      setState(newState);
      setOriginalState(newState);
    },
  });

  const [changeMonthlyBudget, { error: mutationError, loading: loadingMutation }] = useChangeMonthlyBudgetMutation({
    onCompleted: () => {
      // toast.success(
      //   `Měsíční rozpočet nastaven na ${data.changeMonthlyBudget?.organization?.monthlyBudget?.amount} ${data.changeMonthlyBudget?.organization?.monthlyBudget?.currency}`
      // );
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);
        if (typeof onComplete === "function") {
          onComplete();
        }
      }, 2000);
    },
  });

  if (loading) {
    return <SmallLoader />;
  }

  const monthlyBudgetString = state.monthlyBudget.replace(/\s+/, "").replace(",", ".");
  const isBudgetValidNumber = !!monthlyBudgetString.match(TEST_NUMBER);
  const monthlyBudget = parseFloat(isBudgetValidNumber ? monthlyBudgetString : originalState.monthlyBudget);
  const noChanges = originalState.monthlyBudget == state.monthlyBudget;
  const underLimit = monthlyBudget < MINIMAL_MONTHLY_BUDGET;
  const overLimit = monthlyBudget > MAXIMAL_MONTHLY_BUDGET;

  let error;
  if (!isBudgetValidNumber) {
    error = t("Not a valid number");
  } else if (underLimit) {
    error = t("Must be greater than", {
      minimalAmount: formatMoney({
        amount: MINIMAL_MONTHLY_BUDGET,
        currency: state.currency,
      }),
    });
  } else if (overLimit) {
    error = t("Must be lower than", {
      maximalAmount: formatMoney({
        amount: MAXIMAL_MONTHLY_BUDGET,
        currency: state.currency,
      }),
    });
  }

  const addon = (
    <span>
      {!afterEditButton && noChanges && !isSaved ? (
        <span />
      ) : (
        <span>
          <Button
            data-test-id="monthly-budget-save-button"
            disabled={!isBudgetValidNumber || loadingMutation || isSaved || underLimit}
            style={{ margin: "0px" }}
            variant="action"
            onClick={() => changeMonthlyBudget({ variables: { currency: state.currency, monthlyBudget } })}
          >
            {isSaved ? (
              <span style={{ display: "flex", alignItems: "center" }}>
                <CheckmarkIcon /> &nbsp; {t("Saved")}
              </span>
            ) : (
              t("Save")
            )}
          </Button>
          {afterEditButton && !isSaved && (
            <Button style={{ margin: "0 0 0 10px" }} variant="muted" onClick={onComplete}>
              {t("Cancel")}
            </Button>
          )}
        </span>
      )}
    </span>
  );

  return (
    <div className="v2form" style={{ display: "flex", justifyItems: "start", alignItems: "top", flexDirection: "row" }}>
      {mutationError && <SystemMessage description={t("there was a problem saving monthly budget")} level="error" />}
      <NumberFieldWithUnit
        addon={addon}
        error={error}
        label={afterEditButton ? t("Label") : ""}
        name="monthly_budget"
        tooltip={t("Tooltip")}
        unit={formatCurrencyUnit(state.currency)}
        value={state.monthlyBudget}
        autoFocus
        onChange={(event) => setState({ ...state, monthlyBudget: event.target.value })}
      />
    </div>
  );
};
