import React from "react";
import "./FieldSet.css";

interface FieldsetProps {
  children: React.ReactNode;
  title: string;
}

const Fieldset: React.FC<FieldsetProps> = ({ children, title }) => {
  return (
    <div className="fieldset">
      <legend className="fieldset-legend">{title}</legend>
      {children}
    </div>
  );
};

export default Fieldset;
