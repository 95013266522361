import React from "react";
import { ActiveImagesAssetLinesQueryT, ImageSuggestionKindT } from "../../graphql/generated/graphql";
import { ImageSuggestion, UploadedImageSuggestion } from "./ImageSuggestion";

type PreviewPropsT = {
  activeImagesAssetLines: NonNullable<
    NonNullable<ActiveImagesAssetLinesQueryT["organization"]>["activeImagesAssetLines"]
  >;
  kind: ImageSuggestionKindT;
};

export const Preview = ({ activeImagesAssetLines, kind }: PreviewPropsT) => {
  const approvedAssetLines = activeImagesAssetLines.filter(
    (assetLine) => (assetLine.kind as string) === (kind as string)
  );
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {approvedAssetLines.map((assetLine) => (
        <div key={assetLine.id} style={{ display: "inline-block", marginRight: "8px" }}>
          {assetLine.imageSuggestion && <ImageSuggestion {...assetLine.imageSuggestion} />}
          {assetLine.uploadedImage && <UploadedImageSuggestion {...assetLine.uploadedImage} />}
        </div>
      ))}
    </div>
  );
};
