import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalProps } from "@shoptet/ui";
import { PageInfo } from "iframe-resizer";

const currentStateOfParentWindow = {
  offsetTop: 0,
  scrollTop: 0,
  clientHeight: 0,
  iframeHeight: 0,
};

const pageInfoCallback = ({ clientHeight, iframeHeight, offsetTop, scrollTop }: PageInfo) => {
  currentStateOfParentWindow.offsetTop = offsetTop;
  currentStateOfParentWindow.scrollTop = scrollTop;
  currentStateOfParentWindow.clientHeight = clientHeight;
  currentStateOfParentWindow.iframeHeight = iframeHeight;
};
document.addEventListener("DOMContentLoaded", () => {
  const initInterval = setInterval(() => {
    if (window.parentIFrame) {
      window.parentIFrame.getPageInfo(pageInfoCallback);
      clearInterval(initInterval);
    }
  }, 100);
});

export const ModalWrapper = ({
  disableScrollRestore,
  isOpen,
  ...props
}: ModalProps & { disableScrollRestore?: boolean }) => {
  const container = document.getElementById("shoptet-root");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState({
    offsetTop: 0,
    scrollTop: 0,
    clientHeight: 0,
    opened: false,
  });

  const onModalClose = useCallback(() => {
    if (disableScrollRestore) {
      return;
    }
    setState((prev) => {
      if (!prev.opened) {
        return prev;
      }
      setTimeout(() => {
        if (container) {
          container.style.height = "auto";
        }
        const scrollTop = prev.scrollTop;
        setTimeout(() => {
          window.parentIFrame?.scrollTo(0, scrollTop);
        }, 50);
      }, 100);

      return {
        ...prev,
        opened: false,
      };
    });
  }, [container, disableScrollRestore]);

  useEffect(() => {
    if (isOpen) {
      setState({
        offsetTop: currentStateOfParentWindow.offsetTop,
        scrollTop: currentStateOfParentWindow.scrollTop,
        clientHeight: currentStateOfParentWindow.clientHeight,
        opened: true,
      });
      if (!disableScrollRestore) {
        if (container) {
          container.style.height = `${Math.min(
            currentStateOfParentWindow.clientHeight,
            currentStateOfParentWindow.iframeHeight
          )}px`;
        }
        window.parentIFrame?.scrollTo(0, currentStateOfParentWindow.offsetTop);
      }
    }
  }, [container, disableScrollRestore, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      onModalClose();
    }
  }, [isOpen, onModalClose]);

  return <Modal isOpen={isOpen} {...props} />;
};
