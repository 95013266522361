import React, { useState } from "react";
import { Button } from "@shoptet/ui";
import { Trans, useTranslation } from "react-i18next";
import {
  ActiveImagesAssetLinesQueryT,
  AssetLineDraftStateT,
  AssetLineKindT,
  ImageSuggestionKindT,
  ImageSuggestionT,
  ImageSuggestionsQueryT,
  OrganizationValidateImagesQueryT,
  ValidationErrorKindT,
  useCreateAssetLineMutation,
} from "../../graphql/generated/graphql";
import { DeleteAssetLineAction, RestoreDeleteDraftStateAssetlineAction } from "../AssetLineActions";
import { Icon } from "../Icons";
import { UploadImageWithModal } from "../UploadImage";
import { ImageSuggestion, UploadedImageSuggestion } from "./ImageSuggestion";
import "./index.css";

const UserIcon = () => (
  <svg fill="none" height="27" viewBox="0 0 26 27" width="26" xmlns="http://www.w3.org/2000/svg">
    <rect fill="#14B1EF" height="26" rx="13" width="26" y="0.799805" />
    <path
      d="M16.3748 10.7998C16.3748 12.6639 14.8639 14.1748 12.9998 14.1748C11.1357 14.1748 9.62481 12.6639 9.62481 10.7998C9.62481 8.93568 11.1357 7.4248 12.9998 7.4248C14.8639 7.4248 16.3748 8.93568 16.3748 10.7998ZM12.9998 14.9246C10.1046 14.9246 7.75 17.2798 7.75 20.1744C7.75 20.5867 8.0875 20.9242 8.49981 20.9242H17.4998C17.9121 20.9242 18.2496 20.5867 18.2496 20.1744C18.2496 17.2792 15.8944 14.9246 12.9998 14.9246Z"
      fill="white"
    />
  </svg>
);

type ImageSuggestionActionPropsT = {
  disabled?: boolean;
  draft?: boolean;
  isSelected?: boolean;
  suggestion?: Pick<ImageSuggestionT, "id" | "kind" | "assetLineCount">;
};
const ImageSuggestionAction = ({ disabled, draft, isSelected, suggestion }: ImageSuggestionActionPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Wizard Image Selection Page" });
  const [approveAssetLine, {}] = useCreateAssetLineMutation();
  return (
    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px" }}>
      {isSelected ? (
        <Button size="sm" style={{ width: "100%" }} variant="action">
          {t("selected")}
        </Button>
      ) : (
        <Button
          data-test-id={`${suggestion?.kind}-approve`}
          disabled={!suggestion || disabled}
          size="sm"
          style={{ width: "100%" }}
          variant="default"
          onClick={
            suggestion
              ? () => {
                  approveAssetLine({
                    variables: {
                      draft,
                      content: "IMAGE",
                      kind: suggestion.kind as unknown as AssetLineKindT,
                      imageSuggestionId: suggestion.id,
                    },
                  });
                }
              : () => {}
          }
        >
          {t("select")}
        </Button>
      )}
      {isSelected && (
        <span
          style={{ position: "absolute", left: 0, bottom: 0, right: "0", top: "0", background: "white", opacity: 0.6 }}
        />
      )}
      {isSelected && (
        <span style={{ position: "absolute", right: "5px", top: "5px" }}>
          <Icon kind="disable-property" />
        </span>
      )}
    </div>
  );
};

type ImageAssetLinesPropsT = {
  activeImagesAssetLines: NonNullable<
    NonNullable<ActiveImagesAssetLinesQueryT["organization"]>["activeImagesAssetLines"]
  >;
  canFetchMore: boolean;
  draft?: boolean;
  errors: NonNullable<
    NonNullable<NonNullable<OrganizationValidateImagesQueryT["organization"]>["validateImages"]>["errors"]
  >;
  generateMoreSuggestions: () => void;
  generatingMoreSuggestions: boolean;
  header?: string;
  hideSubtext?: boolean;
  imageSuggestions: NonNullable<NonNullable<ImageSuggestionsQueryT["organization"]>["imageSuggestions"]>;
  kind: ImageSuggestionKindT;
  limit: number;
};

export const stateOfItem = (draft?: boolean, draftState?: AssetLineDraftStateT | null) => {
  if (draft && draftState === AssetLineDraftStateT.CreateT) {
    return "draft_create";
  }
  if (!(draft && draftState === AssetLineDraftStateT.DeleteT)) {
    return "existing";
  }
  return "draft_delete";
};

const LIMIT_OF_SUGGESTIONS_TO_SHOW = 12;

export const ImageAssetLines = ({
  activeImagesAssetLines,
  canFetchMore,
  draft,
  errors,
  generateMoreSuggestions,
  generatingMoreSuggestions,
  header,
  hideSubtext,
  imageSuggestions,
  kind,
  limit,
}: ImageAssetLinesPropsT) => {
  const { t } = useTranslation("translation", { keyPrefix: "Wizard Image Selection Page" });
  const ERRORS = {
    [ValidationErrorKindT.TooShortT]: {
      [ImageSuggestionKindT.MarketingImageT]: t("validation error too short landscape banner"),
      [ImageSuggestionKindT.SquareMarketingImageT]: t("validation error too short square banner"),
      [ImageSuggestionKindT.PortraitMarketingImageT]: t("validation error too short portrait banner"),
    },
    [ValidationErrorKindT.TooLongT]: {
      [ImageSuggestionKindT.MarketingImageT]: t("validation error too long landscape banner"),
      [ImageSuggestionKindT.SquareMarketingImageT]: t("validation error too long square banner"),
      [ImageSuggestionKindT.PortraitMarketingImageT]: t("validation error too long portrait banner"),
    },
  };

  const [suggestionsLimit, setSuggestionsLimit] = useState(LIMIT_OF_SUGGESTIONS_TO_SHOW);
  const [generatingSuggestions, setGeneratingSuggestions] = useState(false);

  const approvedAssetLines = activeImagesAssetLines.filter(
    (assetLine) => (assetLine.kind as string) === (kind as string)
  );
  const possibleSuggestions = imageSuggestions.filter(
    (suggestion) => suggestion.kind === kind && !suggestion.disapprovedAt
  );
  const selectedErrors = errors.filter((error) => (error.attribute as string) === (kind as string));
  const notDeletedCount = approvedAssetLines.filter((line) => line.draftState !== AssetLineDraftStateT.DeleteT).length;

  const selectedImageSuggestionIds = approvedAssetLines.map((assetLine) => assetLine.imageSuggestion?.id);
  const alreadyOnLimit = notDeletedCount === limit;
  return (
    <div>
      {header && <h3>{header}</h3>}
      {!hideSubtext && (
        <p>
          <Trans components={{ strong: <strong /> }} i18nKey={"Subtext"} t={t} values={{ limit }} />
        </p>
      )}
      <div className="ImageAssetLines-imagesWrapper" style={{ display: "flex", flexWrap: "wrap" }}>
        {possibleSuggestions.slice(0, suggestionsLimit).map((suggestion) => (
          <div
            key={suggestion.id}
            data-test-id={`${kind}-possible-suggestion-item`}
            style={{
              position: "relative",
              border: "1px solid #E9E9E9",
              padding: "10px",
              display: "inline-block",
              marginRight: "8px",
              marginBottom: "8px",
            }}
          >
            <ImageSuggestion key={suggestion.svgLink} {...suggestion} />
            <ImageSuggestionAction
              disabled={alreadyOnLimit}
              draft={draft}
              isSelected={selectedImageSuggestionIds.indexOf(suggestion.id) !== -1}
              suggestion={suggestion}
            />
          </div>
        ))}
        {generatingSuggestions &&
          Array.from({ length: LIMIT_OF_SUGGESTIONS_TO_SHOW }, (value, index) => index + 1).map((position) => (
            <div
              key={position}
              className="loadingAnimation"
              style={{
                position: "relative",
                border: "1px solid #E9E9E9",
                padding: "10px",
                display: "inline-block",
                marginRight: "8px",
                marginBottom: "8px",
              }}
            >
              <div style={{ border: "1px solid #eee", display: "inline-block" }}>
                <div className={`ImageWrapper--${kind}`}>
                  <div className="ImageWrapper--Preview" />
                </div>
              </div>
              <ImageSuggestionAction />
            </div>
          ))}
      </div>
      <div
        className="ImageAssetLines-ButtonsToGenerate"
        style={{ display: "flex", alignItems: "center", margin: "10px 0" }}
      >
        {possibleSuggestions.length > suggestionsLimit || canFetchMore ? (
          <Button
            disabled={generatingSuggestions || generatingMoreSuggestions}
            variant="muted"
            onClick={() => {
              if (possibleSuggestions.length > suggestionsLimit) {
                setGeneratingSuggestions(true);
                setTimeout(() => {
                  setSuggestionsLimit(suggestionsLimit + LIMIT_OF_SUGGESTIONS_TO_SHOW);
                  setGeneratingSuggestions(false);
                }, (Math.floor(Math.random() * 3) + 2) * 500);
              } else {
                generateMoreSuggestions();
                setSuggestionsLimit(suggestionsLimit + LIMIT_OF_SUGGESTIONS_TO_SHOW);
              }
            }}
          >
            {t(
              generatingSuggestions || generatingMoreSuggestions
                ? "generation of suggestion in progress"
                : "generate new suggestions"
            )}
          </Button>
        ) : (
          <Button variant="muted" disabled>
            {t("no more image suggestions")}
          </Button>
        )}
        <span style={{ marginInline: "10px" }}>{t("or")}</span>
        <UploadImageWithModal disabled={alreadyOnLimit} draft={draft} kind={kind} />
      </div>

      {approvedAssetLines.length > 0 && (
        <>
          <h3 style={{ margin: "20px 0" }}>
            {t("Selected baners")} (
            <span style={{ color: notDeletedCount === 0 || notDeletedCount > limit ? "#db1b4e" : "#04C800" }}>
              {notDeletedCount}
            </span>{" "}
            {t("from")} {limit})
          </h3>

          <div className="ImageAssetLines-imagesWrapper" style={{ display: "flex", flexWrap: "wrap" }}>
            {approvedAssetLines.map((assetLine) => (
              <div
                key={assetLine.id}
                data-test-id={`${kind}-approved-item`}
                className={`ImageAssetLine ${
                  draft && assetLine.draftState ? `ImageAssetLine--${assetLine.draftState}` : ""
                }`}
                style={{
                  position: "relative",
                  padding: "10px",
                  display: "inline-block",
                  marginRight: "8px",
                  marginBottom: "8px",
                }}
              >
                {assetLine.imageSuggestion && <ImageSuggestion {...assetLine.imageSuggestion} />}
                {assetLine.uploadedImage && <UploadedImageSuggestion {...assetLine.uploadedImage} />}
                {assetLine.uploadedImage && (
                  <span style={{ position: "absolute", left: "5px", top: "5px" }}>
                    <UserIcon />
                  </span>
                )}
                <div style={{ marginTop: "8px" }}>
                  {stateOfItem(draft, assetLine.draftState) === "existing" && (
                    <DeleteAssetLineAction draft={draft} id={assetLine.id} width="100%" />
                  )}
                  {stateOfItem(draft, assetLine.draftState) === "draft_create" && (
                    <>
                      <div className="actionButton">
                        <DeleteAssetLineAction draft={draft} id={assetLine.id} width="100%" deleteDraft />
                      </div>
                      <div className="stateInfo">
                        <Button style={{ width: "100%" }} variant="action">
                          {t("Will be added")}
                        </Button>
                      </div>
                    </>
                  )}
                  {stateOfItem(draft, assetLine.draftState) === "draft_delete" && (
                    <>
                      <div className="actionButton">
                        <RestoreDeleteDraftStateAssetlineAction id={assetLine.id} />
                      </div>
                      <div className="stateInfo">
                        <Button style={{ width: "100%" }} variant="warning">
                          {t("Will be removed")}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <br />
      {selectedErrors.length > 0 && (
        <div style={{ color: "#db1b4e" }}>{selectedErrors.map((error) => error?.kind && ERRORS[error.kind][kind])}</div>
      )}
    </div>
  );
};
