import { useCallback, useState } from "react";
import { OrganizationStateT, useChangeCampaignSettingsMutation } from "../../graphql/generated/graphql";

export const useChangeState = () => {
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [changeCampaignSettinMutation] = useChangeCampaignSettingsMutation({
    onCompleted: () => {
      setUpdateInProgress(false);
    },
  });

  const changeCampaignSetting = useCallback(
    (state: OrganizationStateT) => {
      setUpdateInProgress(true);
      return changeCampaignSettinMutation({ variables: { state } });
    },
    [setUpdateInProgress, changeCampaignSettinMutation]
  );

  return {
    updateInProgress,
    changeCampaignSetting,
  };
};
