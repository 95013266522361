import { StatsCard } from "@shoptet/ui";
import { useTranslation } from "react-i18next";
import { CurrencyT, useSummaryStatsQuery } from "../../graphql/generated/graphql";
import { formatInteger, formatMoney } from "../../i18n/formatNumbers";

export const SummaryStats = () => {
  const { t } = useTranslation("translation", { keyPrefix: "Components.Stats" });
  const { data, loading } = useSummaryStatsQuery();

  if (loading) {
    return (
      <div className="statsCardGrid">
        <StatsCard
          icon="impressions"
          title={t("Metric Impressions")}
          tooltip={t("Metric Impressions tooltip")}
          value="0"
        />
        <StatsCard icon="clicks" title={t("Metric Clicks")} tooltip={t("Metric Clicks tooltip")} value="0" />
        <StatsCard icon="orders" title={t("Metric Conversions")} tooltip={t("Metric Conversions tooltip")} value="0" />
        <StatsCard
          icon="money"
          title={t("Metric Conversion Value")}
          tooltip={t("Metric Conversion Value tooltip")}
          value={formatMoney({ amount: 0, currency: CurrencyT.CzkT })}
        />
        <StatsCard
          icon="money"
          title={t("Metric Average Conversion Value")}
          tooltip={t("Metric Average Conversion Value tooltip")}
          value={formatMoney({ amount: 0, currency: CurrencyT.CzkT })}
        />
        <StatsCard
          icon="package"
          title={t("Metric Credit")}
          tooltip={t("Metric Credit tooltip")}
          value={formatMoney({ amount: 0, currency: CurrencyT.CzkT })}
        />
      </div>
    );
  }
  return (
    <div className="statsCardGrid">
      <StatsCard
        icon="impressions"
        title={t("Metric Impressions")}
        tooltip={t("Metric Impressions tooltip")}
        value={
          data?.organization?.summaryStats.impressions
            ? formatInteger(data?.organization?.summaryStats.impressions)
            : "0"
        }
      />
      <StatsCard
        icon="clicks"
        title={t("Metric Clicks")}
        tooltip={t("Metric Clicks tooltip")}
        value={data?.organization?.summaryStats.clicks ? formatInteger(data?.organization?.summaryStats.clicks) : "0"}
      />
      <StatsCard
        icon="orders"
        title={t("Metric Conversions")}
        tooltip={t("Metric Conversions tooltip")}
        value={
          data?.organization?.summaryStats.conversions
            ? formatInteger(data?.organization?.summaryStats.conversions)
            : "0"
        }
      />
      <StatsCard
        icon="money"
        title={t("Metric Conversion Value")}
        tooltip={t("Metric Conversion Value tooltip")}
        value={
          data?.organization?.summaryStats.conversionValue
            ? formatMoney(data?.organization?.summaryStats.conversionValue)
            : formatMoney({ amount: 0, currency: CurrencyT.CzkT })
        }
      />
      <StatsCard
        icon="inCart"
        title={t("Metric Average Conversion Value")}
        value={
          data?.organization?.summaryStats.averageConversionValue
            ? formatMoney(data?.organization?.summaryStats.averageConversionValue)
            : formatMoney({ amount: 0, currency: CurrencyT.CzkT })
        }
      />
      <StatsCard
        icon="package"
        title={t("Metric Credit")}
        tooltip={t("Metric Credit tooltip")}
        value={
          data?.organization?.summaryStats.credit
            ? formatMoney(data?.organization?.summaryStats.credit)
            : formatMoney({ amount: 0, currency: CurrencyT.CzkT })
        }
      />
    </div>
  );
};
